/*******************/
/* _l-footer.scss */
/*****************/

.footer {
  border-top: 1px solid $color-gray-lighter;
  @include breakpoint($medium + 1) {
    display: flex;
  }
  .footer-left {
    align-items: center;
    background-color: $color-turquoise;
    display: flex;
    justify-content: center;
    padding: 2.5em 1em;
    @include breakpoint($medium + 1) {
      width: 50%;
    }
    .footer-left__link {
      text-decoration: none;
    }
    .footer-left__text {
      margin-left: 1em;
      .footer-left__title {
        color: $color-white;
        font-size: $font-normal;
        font-weight: 700;
        @include breakpoint($xsmall + 1) {
          font-size: $font-discrete;
        }
        @include breakpoint($small + 1) {
          font-size: $font-large;
        }
      }
      .footer-left__description {
        color: $color-white;
        font-size: $font-small;
        @include breakpoint($xsmall + 1) {
          font-size: $font-normal;
        }
      }
    }
  }
  .footer-right {
    background-color: $color-white;
    padding: 1.5em;
    @include breakpoint($medium + 1) {
      padding: 1.5em 3em;
      width: 50%;
    }
    .footer-right__top {
      .footer-right__cta {
        @include button-empty(true);
        align-items: center;
        display: inline-flex;
        font-size: $font-xsmall;
        padding-bottom: .2em;
        padding-top: .2em;
        @include breakpoint($xsmall + 1) {
          font-size: $font-small;
        }
        &::before {
          @include sprite--icon-chevron-right-gray;
          content: '';
          display: block;
        }
        &::after {
          @include sprite--icon-user;
          content: '';
          display: block;
          order: -1;
        }
      }
    }
    .footer-right__bottom {
      align-items: center;
      display: flex;
      justify-content: space-between;
      .footer-right__description {
        font-size: $font-small;
        margin-top: 1.5em;
      }
      .social-footer {

      }
    }
  }
}

/* Safari 10.1+ */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {
    .footer {
      min-height: 9em;
    }
  }
}