// =============================================================================
// Breakpoints.
// =============================================================================

$xsmall:        460px;
$small:         640px;
$medium:        768px;
$intermediate:  960px;
$normal:        1024px;
$large:         1200px;
$full:          1440px;

// =============================================================================
// Dimensions.
// =============================================================================

$maxwidth: 1350px;

$header-height:       4.8rem;
$header-height-small: 3.5rem;

// =============================================================================
// Colors.
// =============================================================================

// Brand colors.
$color-azure:                 #E1F1F0;
$color-turquoise-lightest:    #F2F9F8;
$color-turquoise:             #37A49A;
$color-turquoise-dark:        #1E746C;
$color-yellow:                #FFC244;

// Shades of grey.
$color-white:                 #FFFFFF;
$color-gray-lightest:         #F3F3F3;
$color-gray-lighter:          #E5E5E5;
$color-gray-light:            #9B9B9B;
$color-gray:                  #4A4A4A;
$color-black:                 #000000;

// Gradients
$gradient-turquoise:  linear-gradient(225deg, #54b1a8, #5db5ad 18%, #37a49a 30%, #37a49a 50%, #4bada4 78%, #88c9c3);

// =============================================================================
// Typography.
// =============================================================================

@import url('https://fonts.googleapis.com/css?family=Quicksand:400,700|Roboto:300,400,500,700&display=swap');

$font-primary:      'Quicksand', sans-serif;
$font-secondary:    'Roboto', sans-serif;

$font-xsmall:       pxrem(12);
$font-small:        pxrem(14);
$font-normal:       pxrem(16);
$font-medium:       pxrem(18);
$font-discrete:     pxrem(20);
$font-intermediate: pxrem(24);
$font-large:        pxrem(28);
$font-xlarge:       pxrem(32);
$font-xxlarge:      pxrem(38);
$font-xxxlarge:     pxrem(46);
$font-huge:         pxrem(60);

// =============================================================================
// Transitions.
// =============================================================================
$transition-default:  220ms ease-out;
$transition-slowmo:   750ms cubic-bezier(.55, .75, .00, 1);
$transition-slow:     3000ms cubic-bezier(.55, .75, .00, 1);
