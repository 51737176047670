/*************************/
/* _l-section-help.scss */
/***********************/

.section__help {
  display: none;
  @include breakpoint($large) {
    background-color: $color-turquoise-lightest;
    background-image: url('../images/elements/help.svg');
    background-position: 1em 1em;
    background-repeat: no-repeat;
    border: 2px solid $color-white;
    box-shadow: 0 0 8px 0 rgba(0,0,0,.17);
    border-radius: 8px;
    display: block;
    margin-top: 6.2em;
    overflow: hidden;
  }
  .help-box__body {
    padding: 3em 1em 1em;
    position: relative;
    .help-box__title {
      color: $color-turquoise;
      font-size: $font-large;
      text-align: center;
    }
    .help-box__description {
      line-height: 1.5;
      margin-top: .25em;
      text-align: center;
    }
    .help-box__action {
      border-bottom: 3px solid $color-yellow;
      border-top: 3px solid $color-yellow;
      color: $color-turquoise;
      font-size: $font-discrete;
      margin-bottom: 1em;
      margin-top: 1em;
      padding-bottom: .5em;
      padding-top: .5em;
      text-align: center;
    }
    .help-box__item {
      color: $color-turquoise;
      font-size: $font-small;
      margin-bottom: .5em;
      margin-top: .5em;
    }
  }
  .help-box__footer {
    background-color: $color-turquoise;
    padding: 1.25em;
    text-align: center;
    .help-box__cta {
      @include button-full;
    }
  }
}

.section__help-mobile {
  background-color: $color-turquoise;
  bottom: 0;
  left: 0;
  padding: 1em .5em;
  position: fixed;
  right: 0;
  z-index: 1;
  @include breakpoint($large) {
    display: none;
  }
  .help-box__body {
    @include maxWidth(40em);
    align-items: center;
    display: flex;
    justify-content: center;
    .help-box__left {
      max-width: 14em;
      @include breakpoint($small) {
        max-width: 20em;
      }
      .help-box__title {
        color: $color-yellow;
        font-size: $font-medium;
        font-weight: 700;
        @include breakpoint($small) {
          font-size: $font-large;
        }
      }
      .help-box__description {
        color: $color-white;
        font-size: $font-small;
        font-weight: 700;
        @include breakpoint($small) {
          font-size: $font-discrete;
        }
      }
    }
    .help-box__right {
      .help-box__cta {
        @include button-full;
        font-size: $font-normal !important;
        padding: .35em 1.75em;
        @include breakpoint($small) {
          font-size: $font-discrete !important;
        }
      }
    }
  }
}