/****************************/
/* _l-section-reviews.scss */
/**************************/

.section__reviews {
  margin-bottom: 3em;
  .reviews__title {
    @include title-small;
  }
  .reviews__wrapper {
    border: 1px solid rgba($color-gray-light, .35);
    border-radius: 8px;
    .reviews__header {
      border-bottom: 1px solid rgba($color-gray-light, .35);
      padding: 1em;
      @include breakpoint($small) {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
      .reviews__add {
        margin-top: 1em;
        @include breakpoint($small) {
          margin-top: 0;
        }
        .reviews__add-cta {
          @include button-empty(true);
          text-transform: none;
          &::before {
            @include sprite--icon-chevron-right-gray;
            content: '';
            display: block;
          }
        }
      }
    }
    .reviews__list {

    }
  }
}