.infobox {
  background-color: $color-white;
  height: 100%;
  margin: 0 auto;
  max-width: 40em;
  padding: 2em 1.5em;
  position: relative;
  @include breakpoint($small) {
    padding: 2em;
  }
  &::before {
    background-image: url('../images/landing/banner.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 8em;
    left: -.53em;
    position: absolute;
    top: -.53em;
    width: 8em;
    @include breakpoint($small) {
      height: 11em;
      left: -.7175em;
      top: -.7175em;
      width: 11em;
    }
  }
  .infobox__title {
    color: $color-yellow;
    font-size: $font-large;
    margin: 1em auto 0;
    max-width: 11em;
    text-align: center;
    text-transform: uppercase;
    @include breakpoint($medium) {
      font-size: $font-xlarge;
    }
  }
  .infobox__process {
    display: none;
    @include breakpoint($xsmall) {
      display: flex;
    }
  }
}