/*************************/
/* _l-section-info.scss */
/***********************/

.section__info {
  .info__title {
    @include title-small;
  }
  .info__description {
    font-size: $font-small;
    @include breakpoint($small) {
      font-size: $font-normal;
    }
  }
  .info__rooms {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .info__room-item {
      margin-bottom: .75em;
      margin-left: .5em;
      margin-right: .5em;
      @include breakpoint($small) {
        margin-left: 1em;
        margin-right: 1em;
      }
      .info__room-image {
        margin: 0 auto;
        max-width: 4.5em;
      }
      .info__room-text {
        font-size: $font-small;
        font-weight: 700;
        margin-top: .2em;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
}