/*************************************/
/* _l-section-contact-designer.scss */
/************************************/

.section__contact-designer {
  background-color: $color-gray-lightest;
  border-radius: 8px;
  margin-bottom: 3em;
  padding: 1em;
  @include breakpoint($small) {
    padding: 2.5em;
  }
  .contact-designer__title {
    color: $color-turquoise;
    font-size: $font-discrete;
    text-align: center;
    @include breakpoint($small) {
      font-size: $font-intermediate;
    }
    @include breakpoint($medium) {
      font-size: $font-large;
    }
  }
  .contact-designer__form {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5em;
  }
  .form__cta-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    .form__cta {
      @include button-full-small;
    }
  }
}