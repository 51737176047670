/*************************/
/* _l-section-hero.scss */
/***********************/

.section__hero {
  height: 90vh;
  position: relative;
  width: 100%;
  @include breakpoint($small + 1) {
    align-items: center;
    background-color: #77FFF2;
    display: flex;
    justify-content: center;
    height: 100vh;
  }
  @include breakpoint($small + 1) {
    &::after {
      @include opacityLayer;
    }
  }
  .hero__gallery {
    height: 50%;
    width: 100%;
    @include breakpoint($small + 1) {
      bottom: 0;
      left: 0;
      height: auto;
      position: absolute;
      right: 0;
      top: 0;
    }
    div {
      height: 100% !important;
    }
    .hero__gallery-content {
      .hero__gallery-item {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
  .hero__content {
    background-color: $color-white;
    padding-top: 2em;
    position: relative;
    text-align: center;
    z-index: 1;
    @include breakpoint($small + 1) {
      background-color: transparent;
      padding-top: 0;
    }
    .hero__content-label {
      color: $color-gray;
      font-size: $font-discrete;
      font-weight: 700;
      will-change: transform;
      @include breakpoint($small + 1) {
        color: $color-white;
        font-size: $font-xxxlarge;
      }
    }
    .hero__content-box {
      background-color: rgba($color-white,.9);
      border-radius: 15px;
      border: 1px solid $color-white;
      color: $color-turquoise;
      cursor: pointer;
      display: inline-block;
      font-size: $font-xxlarge;
      font-weight: 700;
      margin-bottom: .175em;
      margin-top: .175em;
      padding: 0 1.25em;
      position: relative;
      will-change: transform;
      @include breakpoint($small + 1) {
        display: block;
        font-size: $font-xxxlarge;
        margin-bottom: .3em;
        margin-top: .3em;
        padding: .25em;
      }
      &::after {
        @include sprite--icon-chevron-down-yellow;
        content: '';
        display: block;
        position: absolute;
        right: .25em;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .hero__content-description {
      font-size: $font-medium;
      letter-spacing: -0.5px;
      will-change: transform;
      @include breakpoint($small + 1) {
        color: $color-white;
        font-size: $font-xlarge;
        text-shadow: 0 2px 4px rgba(0,0,0,.5);
      }
    }
    .hero__content-cta {
      @include button-full('true');
      margin-top: .6em;
      &::before {
        @include sprite--icon-search;
      }
    }
  }
  .hero__scroll {
    background-color: $color-turquoise;
    border-radius: 50%;
    bottom: -1.5em;
    cursor: pointer;
    height: 3em;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 3em;
    will-change: transform;
    &::before {
      @include sprite--icon-chevron-down-white;
      content: '';
      display: block;
      margin: .2em auto;
    }
    z-index: 1;
  }
}