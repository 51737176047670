/*********************************/
/* _l-section-section-whys.scss */
/*******************************/

.section_whys {
  padding: 2.5em 0 1em;
  @include breakpoint($medium + 1) {
    padding: 4em 0 1em;
  }
  .whys-top {
    padding: .5em;
    .whys-top__title {
      font-size: $font-normal;
      text-align: center;
      @include breakpoint($xsmall + 1) {
        font-size: $font-discrete;
      }
      @include breakpoint($medium + 1) {
        font-size: $font-large;
      }
    }
    .whys-top__wrapper {
      margin-top: 2em;
      @include breakpoint($medium + 1) {
        display: flex;
        justify-content: space-between;
        margin-top: 3em;
      }
      .whys-top__item {
        margin-bottom: .75em;
        will-change: transform;
        @include breakpoint($medium + 1) {
          margin-bottom: 0;
          margin-left: .75em;
          margin-right: .75em;
          text-align: center;
          width: calc(33% - 1.5em);
        }
        @include breakpoint($normal + 1) {
          margin-left: 2em;
          margin-right: 2em;
        }
        .whys-top__item-title {
          color: $color-yellow;
          font-size: $font-large;
          font-weight: 700;
          @include breakpoint($xsmall + 1) {
            font-size: $font-xlarge;
          }
          @include breakpoint($medium + 1) {
            font-size: $font-xxlarge;
          }
        }
        .whys-top__item-description {
          font-size: $font-small;
          @include breakpoint($xsmall + 1) {
            font-size: $font-normal;
          }
          @include breakpoint($medium + 1) {
            font-size: $font-discrete;
          }
        }
      }
    }
  }
  .whys-bottom {
    background-color: $color-gray-lightest;
    margin-top: 4em;
    padding: 2em 1em;
    .whys-bottom__wrapper {
      @include breakpoint($small + 1) {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
      .whys-bottom__item {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 1em;
        @include breakpoint($small + 1) {
          display: block;
          margin-bottom: .25em;
          margin-left: .5em;
          margin-right: .5em;
          max-width: 13.75rem;
          width: calc(20% - 1em);
        }
        .whys-bottom__item-image {
          margin-right: 1em;
          max-width: 5em;
          @include breakpoint($small + 1) {
            margin: 0 auto;
            max-width: 6em;
          }
          @include breakpoint($medium + 1) {
            max-width: 100%;
          }
        }
        .whys-bottom__item-description {
          font-weight: 700;
          margin-top: .75em;

          @include breakpoint($small + 1) {
            text-align: center;
          }
        }
      }
    }
  }
}