@mixin svg-common {
    background-image: url("../images/sprite.svg");
    background-repeat: no-repeat;
}

@mixin sprite--icon-carousel-left-white {
    @include svg-common;
    background-position: 34.72222222222222% 0;
    width: 64px;
    height: 64px;
}

@mixin sprite--icon-carousel-left-yellow {
    @include svg-common;
    background-position: 53.75% 53.225806451612904%;
    width: 48px;
    height: 48px;
}

@mixin sprite--icon-carousel-right-white {
    @include svg-common;
    background-position: 79.16666666666667% 0;
    width: 64px;
    height: 64px;
}

@mixin sprite--icon-carousel-right-yellow {
    @include svg-common;
    background-position: 23.75% 53.225806451612904%;
    width: 48px;
    height: 48px;
}

@mixin sprite--icon-chevron-down-white {
    @include svg-common;
    background-position: 75.84269662921348% 77.18120805369128%;
    width: 30px;
    height: 23px;
}

@mixin sprite--icon-chevron-down-yellow {
    @include svg-common;
    background-position: 0 100%;
    width: 30px;
    height: 23px;
}

@mixin sprite--icon-chevron-left-white {
    @include svg-common;
    background-position: 94.68085106382979% 74.65753424657534%;
    width: 20px;
    height: 26px;
}

@mixin sprite--icon-chevron-right-gray {
    @include svg-common;
    background-position: 93.19371727748691% 90%;
    width: 17px;
    height: 22px;
}

@mixin sprite--icon-chevron-right-white {
    @include svg-common;
    background-position: 94.68085106382979% 39.726027397260275%;
    width: 20px;
    height: 26px;
}

@mixin sprite--icon-close {
    @include svg-common;
    background-position: 0 83.33333333333333%;
    width: 34px;
    height: 34px;
}

@mixin sprite--icon-list {
    @include svg-common;
    background-position: 97.8021978021978% 57.142857142857146%;
    width: 26px;
    height: 25px;
}

@mixin sprite--icon-phone {
    @include svg-common;
    background-position: 39.08045977011494% 83.33333333333333%;
    width: 34px;
    height: 34px;
}

@mixin sprite--icon-pin-big {
    @include svg-common;
    background-position: 0 0;
    width: 50px;
    height: 66px;
}

@mixin sprite--icon-pin-small {
    @include svg-common;
    background-position: 0 53.65853658536585%;
    width: 38px;
    height: 49px;
}

@mixin sprite--icon-search {
    @include svg-common;
    background-position: 58.285714285714285% 82.14285714285714%;
    width: 33px;
    height: 32px;
}

@mixin sprite--icon-star-empty {
    @include svg-common;
    background-position: 100% 0;
    width: 30px;
    height: 29px;
}

@mixin sprite--icon-star-full {
    @include svg-common;
    background-position: 100% 20.27972027972028%;
    width: 30px;
    height: 29px;
}

@mixin sprite--icon-user {
    @include svg-common;
    background-position: 19.54022988505747% 83.33333333333333%;
    width: 34px;
    height: 34px;
}

@mixin sprite--icon-web {
    @include svg-common;
    background-position: 77.90697674418605% 48.175182481751825%;
    width: 36px;
    height: 35px;
}

