.h-wrapper {
  @include maxWidth;
}

.h-hide {
  display: none;
}

.h-visually-hidden {
  @include visuallyhidden;
}

.h-noscroll {
  overflow: hidden;
}

.h-animation-hide {
  opacity: 0;
  visibility: hidden;
}