// =============================================================================
// Generic.
// =============================================================================

// Set max width and center element.
@mixin maxWidth($maxWidth: $maxwidth) {
  max-width: $maxWidth;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
}

// =============================================================================
// Typography
// =============================================================================

// Font smoothing.
@mixin fontSmoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// =============================================================================
// Helpers.
// =============================================================================

// Hide from both screenreaders and browsers.
@mixin hidden {
  display: none !important;
  visibility: hidden;
}

// Hide only visually, but have it available for screenreaders.
@mixin visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Clearfix.
@mixin clearfix {
  *zoom: 1;
  &:after,
  &:before {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

// Create full block absolute link
@mixin blockLink {
  &::before {
    bottom: 0;
    content: '';
    cursor: pointer;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }
}

// =============================================================================
// Elements.
// =============================================================================

@mixin opacityLayer($color: rgba($color-black, .4)) {
  background-color: rgba($color-black, 0.4);
  bottom: 0;
  content: '';
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@mixin button-full($icon: null) {
  background-color: $color-yellow;
  box-shadow: 0 0 8px 0 rgba(0,0,0,.2);
  border-radius: 6.25em;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  font-size: $font-discrete;
  line-height: 1;
  padding: .6em 2.5em;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color $transition-default;
  @include breakpoint($small + 1) {
    font-size: $font-intermediate;
  }
  &:hover {
    background-color: darken($color-yellow, 10%);
  }

  @if $icon {
    padding-left: 3em;
    &::before {
      content: '';
      display: block;
      left: 1em;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
    }
  }
}

@mixin button-full-small($icon: null) {
  background-color: $color-yellow;
  box-shadow: 0 0 8px 0 rgba(0,0,0,.2);
  border-radius: 6.25em;
  color: $color-white;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  font-size: $font-medium;
  line-height: 1;
  padding: .6em 2.5em;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color $transition-default;
  @include breakpoint($small + 1) {
    font-size: $font-discrete;
  }
  &:hover {
    background-color: darken($color-yellow, 10%);
  }

  @if $icon {
    padding-left: 3em;
    &::before {
      content: '';
      display: block;
      left: 1em;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
    }
  }
}

@mixin button-empty($icon: null) {
  background-color: transparent;
  border: 1px solid $color-yellow;
  border-radius: 6.25em;
  color: $color-gray;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  font-size: $font-small;
  line-height: 1.5;
  padding: .6em 1.75em;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color $transition-default;
  &:hover {
    background-color: rgba($color-yellow, .125);
    border-color: darken($color-yellow, 10%);
  }

  @if $icon {
    padding-right: 2.5em;
    &::before {
      content: '';
      display: block;
      right: 1em;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
    }
  }
}

@mixin title-small {
  align-items: center;
  color: $color-turquoise;
  display: flex;
  font-size: $font-medium;
  font-weight: 700;
  justify-content: space-between;
  margin-bottom: 1em;
  margin-top: 3em;
  @include breakpoint($small) {
    font-size: $font-discrete;
  }
  &::before,
  &::after {
    background-color: $color-gray-lightest;
    content: '';
    display: block;
    flex-grow: 1;
    height: 1px;
    width: 5%;
    @include breakpoint($small) {
      width: 30%;
    }
  }
  &::before {
    margin-right: 1em;
  }
  &::after {
    margin-left: 1em;
  }
}