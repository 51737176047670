.landing {
  background-image: url('../images/cover/02.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 0 !important;
  position: relative;
  &::before {
    @include opacityLayer;
  }
  .main {
    position: relative;
    z-index: 1;
    @include breakpoint($intermediate) {
      display: flex;
      justify-content: space-between;
    }
    .landing__left,
    .landing__right {
      flex: 1;
      padding: 2em;
    }
  }
}