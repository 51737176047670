/***********************/
/* _c-navigation.scss */
/*********************/

.nav {
  margin-bottom: 1.5em;
  margin-top: 1em;
  width: 80%;
  @include breakpoint($normal) {
    margin-bottom: 0;
    margin-top: 0;
    width: auto;
  }
  .nav__list {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    list-style: none;
    padding-left: 0;
    @include breakpoint($normal) {
      flex-direction: row;
    }
    .nav__item {
      border-bottom: 1px solid $color-gray-lighter;
      margin-left: 1em;
      margin-right: 1em;
      padding-bottom: .75em;
      padding-top: .75em;
      text-align: center;
      width: 100%;
      @include breakpoint($normal) {
        border-bottom: none;
        padding-bottom: 0;
        padding-top: 0;
        width: auto;
      }
      .nav__link {
        color: $color-gray;
        font-weight: 700;
        text-decoration: none;
        text-transform: uppercase;
        width: 100%;
        &::after {
          background-color: transparent;
          content: '';
          display: block;
          height: 3px;
          transition: background-color $transition-default;
          width: 100%;
        }
        @include breakpoint($normal) {
          color: $color-white;
        }
        &:hover {
          @include breakpoint($normal) {
            &::after {
              background-color: $color-yellow;
            }
          }
        }
        &.active {
          color: $color-yellow;
          @include breakpoint($normal) {
            color: $color-white;
            &::after {
              background-color: $color-yellow;
            }
          }
        }
      }
    }
  }
}

.sticky {
  .nav .nav__list .nav__item .nav__link {
    color: $color-gray;
  }
}