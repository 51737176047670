/**************************/
/* _c-card-designer.scss */
/************************/

.designer-card {
  margin: .5em;
  width: 20rem;
  @include breakpoint(1350px) {
    width: 25rem;
  }
  &.fluid {
    max-width: 25rem;
    width: calc(100% - 1em);
    @include breakpoint($medium) {
      max-width: 100%;
      width: calc(50% - 2em);
    }
  }
  .designer-card__wrapper {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.17);
    border-radius: 10px;
    height: 100%;
    overflow: hidden;
    padding: 0 0 24px;
    position: relative;
    .designer-card__internal-wrapper {
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      height: 100%;
      max-width: 100%;
      position: relative;
      &::before {
        background-color: $color-white;
        content: "";
        height: 25px;
        left: 0;
        position: absolute;
        top: 100%;
        width: calc(100% - 25px);
      }
      &::after {
        background-image: url('../images/elements/box_cut.png');
        background-repeat: no-repeat;
        background-size: cover;
        content: "";
        height: 25px;
        left: 100%;
        margin-left: -25px;
        position: absolute;
        top: 100%;
        width: 25px;
      }
      .designer-card__header {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 12.5rem;
        position: relative;
        @include breakpoint($normal + 1) {
          height: 15.625rem;
        }
        .designer-card__discount {
          align-items: stretch;
          bottom: 0;
          display: flex;
          left: 0;
          position: absolute;
          span {
            align-items: center;
            background-color: $color-yellow;
            display: flex;
            font-size: $font-small;
            padding: .25em .5em;
          }
          &::after {
            background-image: url('../images/elements/discount-flag.svg');
            background-position: left;
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
            display: block;
            height: 1.65em;
            margin-left: -1px;
            width: 1.25em;
          }
        }
      }
      .designer-card__body {
        background-color: $color-white;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: .5em .75em;
        .designer-card__title {
          color: $color-turquoise;
          font-size: $font-normal;
          padding-bottom: .25em;
          @include breakpoint($xsmall + 1) {
            font-size: $font-medium;
          }
          @include breakpoint($normal + 1) {
            font-size: pxrem(19);
          }
          @include breakpoint($large + 80) {
            font-size: $font-discrete;
          }
        }
        .designer-card__address {
          font-size: $font-small;
          font-weight: 700;
          @include breakpoint($xsmall + 1) {
            font-size: pxrem(15);
          }
          @include breakpoint($large + 80) {
            font-size: $font-normal;
          }
        }
        .designer-card__footer {
          display: flex;
          justify-content: space-between;
          margin-top: auto;
          padding-bottom: .5em;
          padding-top: 2em;
          text-align: left;
          .designer-card__cta {
            @include button-empty(true);
            padding: .4em 1em;
            @include breakpoint($small + 1) {
              padding: .6em 2.75em;
              &::before {
                @include sprite--icon-chevron-right-gray;
                content: '';
                display: block;
              }
            }
          }
        }
      }
    }
  }
  &.showcase {
    .designer-card__header::after {
      /*background-image: url('../images/elements/showcase.svg');*/
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      display: block;
      height: 4.5em;
      position: absolute;
      right: 0;
      top: 0;
      width: 4.5em;
    }
  }
}
