/**********************/
/* _c-paginator.scss */
/********************/

.paginator {
  align-items: center;
  display: flex;
  justify-content: center;
  .paginator__arrow {
    color: $color-turquoise;
    cursor: pointer;
    font-weight: 700;
    height: 1.75em;
    line-height: 1.75em;
    margin-left: .6em;
    margin-right: .6em;
    text-align: center;
    transition: color $transition-default;
    width: 1.75em;
    &:hover {
      color: darken($color-turquoise, 7%);
    }
    &.disabled {
      color: $color-gray-lighter;
      cursor: auto;
      pointer-events: none;
    }
  }
  .paginator__page {
    background-color: lighten($color-gray-lighter, 1%);
    border-radius: 50%;
    cursor: pointer;
    height: 1.75em;
    line-height: 1.75em;
    font-weight: 700;
    margin-left: .25em;
    margin-right: .25em;
    text-align: center;
    transition: all $transition-default;
    width: 1.75em;
    &:hover {
      background-color: darken($color-gray-lighter, 3%);
    }
    &.active {
      background-color: $color-turquoise;
      color: $color-white;
    }
  }
}