/************************/
/* _l-page-negozi.scss */
/**********************/

.negozi-page {
  h1 {
    color: $color-turquoise;
    font-size: $font-intermediate;
    font-weight: 700;
    margin-top: .75em;
    @include breakpoint($small) {
      font-size: $font-xlarge;
    }
    @include breakpoint($medium) {
      font-size: $font-xxlarge;
    }
  }
}