/****************************/
/* _l-page-arredatore.scss */
/**************************/

.arredatori-page {
  .arredatori-wrapper {
    @include breakpoint($large) {
      display: flex;
    }
    .arredatori-wrapper__left {
      width: 100%;
      @include breakpoint($large) {
        width: 65%;
      }
    }
    .arredatori-wrapper__right {
      width: 0;
      @include breakpoint($large) {
        width: 35%;
      }
    }
  }
  .footer {
    padding-bottom: 7em;
    @include breakpoint($large) {
      padding-bottom: 0;
    }
  }
}