/*****************************/
/* _c-breadcrumb-shops.scss */
/***************************/

.breadcrumb__shops {
  background-color: $color-gray-lightest;
  .breadcrumb__shops-wrapper {
    padding-bottom: .2em;
    padding-top: .125em;
    .breadcrumb__shops-list {
      list-style: none;
      padding-left: 0;
      .breadcrumb__shops-item {
        display: inline-block;
        &:not(:last-of-type)::after {
          content: '/';
          display: inline-block;
          font-size: $font-xsmall;
          margin-left: .125em;
          margin-right: .125em;
        }
        .breadcrumb__shops-link {
          color: $color-gray;
          font-size: $font-xsmall;
          font-weight: 700;
          text-decoration: none;
          transition: color $transition-default;
        }
      }
    }
  }
}