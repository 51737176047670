.form__element-label {
  display: block;
  font-size: $font-small;
}

.form__element-input {
  background: $color-white;
  border: 2px solid $color-gray-lighter;
  border-radius: 4px;
  font-family: $font-primary;
  line-height: 1.35;
  padding: .25em;
  &.textarea {
    min-height: 8em;
    resize: none;
  }
}

.form-wrapper {
  width: 100%;
  .form__element-wrapper {
    margin-bottom: .5em;
    width: 100%;
    @include breakpoint($small) {
      margin: .5em;
      width: calc(50% - 1em);
    }
    &.wide {
      width: 100%;
      @include breakpoint($small) {
        width: calc(100% - 1em);
      }
    }
    .form__element-input {
      width: 100%;
    }
  }
}

.form__element-wrapper.form__checkbox {
  .form__checkbox-container {
    cursor: pointer;
    display: block;
    font-size: $font-small;
    padding-left: 1.75em;
    position: relative;
    user-select: none;
    .form__element-input.checkbox {
      cursor: pointer;
      height: 0;
      opacity: 0;
      position: absolute;
      width: 0;
    }
    .form__checkmark {
      background-color: $color-white;
      border: 2px solid $color-gray-lighter;
      border-radius: 3px;
      height: 1.2em;
      left: 0;
      position: absolute;
      top: 0;
      width: 1.2em;
    }
  }
}

/* On mouse-over, add a grey background color */
.form__checkbox-container:hover .form__element-input.checkbox ~ .form__checkmark {
  background-color: rgba($color-gray-lightest, .125);
}

/* When the checkbox is checked, add a blue background */
.form__checkbox-container .form__element-input.checkbox:checked ~ .form__checkmark {
  // background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.form__checkmark:after {
  content: '';
  display: none;
  position: absolute;
}

/* Show the checkmark when checked */
.form__checkbox-container .form__element-input.checkbox:checked ~ .form__checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.form__checkbox-container .form__checkmark:after {
  background-image: url('../images/elements/checkmark.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: .9em;
  left: .05em;
  top: .05em;
  width: .9em;
}