/**********************************/
/* _l-section-top-designers.scss */
/********************************/

.section__top_designers {
  padding: 4em 1em;
 .top-designers__title {
    color: $color-turquoise;
    font-size: $font-large;
    font-weight: 400;
    margin-bottom: .5em;
    text-align: center;
    @include breakpoint($xsmall + 1) {
      font-size: $font-xlarge;
    }
    @include breakpoint($medium + 1) {
      font-size: $font-xxlarge;
    }
  }
  .top-designers__container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @include breakpoint($normal) {

    }
  }
  .top-designers__cta-wrapper {
    margin-top: 1.5em;
    text-align: center;
    .top-designers__cta {
      @include button-empty(true);
      &::before {
        @include sprite--icon-chevron-right-gray;
        content: '';
        display: block;
      }
    }
  }
}