/****************************/
/* _l-section-aboutus.scss */
/**************************/

.section__aboutus {
  padding: 2em .5em 1.25em;
  @include breakpoint($small + 1) {
    padding: 2em 1em 4em;
  }
  .aboutus__title {
    color: $color-turquoise;
    font-size: $font-large;
    font-weight: 700;
    margin-bottom: .5em;
    text-align: center;
    @include breakpoint($xsmall + 1) {
      font-size: $font-xlarge;
    }
    @include breakpoint($medium + 1) {
      font-size: $font-xxlarge;
    }
    &::after {
      background-color: $color-yellow;
      content: '';
      display: block;
      height: 3px;
      margin: .2em auto;
      width: 1.5em;
    }
  }
  .aboutus__gallery {
    padding-left: 2.25em;
    padding-right: 2.25em;
    position: relative;
    @include breakpoint($medium + 1) {
      padding-left: 2em;
      padding-right: 2em;
    }
    .opinion {
      max-width: 16.5rem;
      padding: .25em;
      text-align: center;
      .opinion__image {
        margin: 0 auto;
      }
      .opinion__name {
        color: $color-turquoise;
        font-weight: 700;
      }
      .opinion__subtitle {
        font-size: pxrem(13);
        font-weight: 700;
        @include breakpoint($medium + 1) {
          font-size: $font-small;
        }
        &::after {
          background-color: rgba($color-gray-light, .5);
          content: '';
          display: block;
          height: 1px;
          margin: .5em auto;
          width: 2em;
        }
      }
      .opinion__description {
        line-height: 1.4;
      }
    }
    .slick-slide {
      > div {
        text-align: center;
      }
    }
    .slick-arrow {
      position: absolute;
      text-indent: -9999px;
      top: 50%;
      transform: translateY(-50%) scale(.75);
      @include breakpoint($small + 1) {
        transform: translateY(-50%);
      }
      &::after {
        content: '';
        display: block;
      }
      &.slick-prev {
        left: 0;
        &::after {
          @include sprite--icon-carousel-left-yellow;
        }
      }
      &.slick-next {
        right: 0;
        &::after {
          @include sprite--icon-carousel-right-yellow;
        }
      }
    }
  }
}