/*************************/
/* _c-review-modal.scss */
/***********************/

.review-modal {
  .review-modal__rate {
    border-bottom: 1px solid rgba($color-gray-lighter, .5);
    padding: 1.25em 1.25em 2em;
    .rate__title {
      color: $color-turquoise;
      font-size: $font-small;
      font-weight: 700;
    }
    .rate__stars-wrapper {
      margin-bottom: .25em;
      margin-top: .5em;
      .rate__star {
        cursor: pointer;
      }
    }
    .rate__decription {
      font-family: $font-secondary;
      font-size: $font-small;
      font-style: italic;
    }
  }
  .review-modal__review {
    padding: 1.25em;
    .review-modal__review-form {
      .form__element-label {
        color: $color-turquoise;
        font-weight: 700;
        margin-bottom: .25em;
      }
      .form__element-input.textarea {
        width: 100%;
      }
      .form__element-note {
        font-family: $font-secondary;
        font-size: $font-small;
        font-style: italic;
        text-align: right;
      }
    }
  }
  .review-modal__actions {
    text-align: center;
    .review-modal__cta {
      @include button-full-small;
    }
  }
}