/*******************/
/* _c-social.scss */
/*****************/

.nav__social {
  .social__menu {
    display: flex;
    list-style: none;
    padding-left: 0;
    .social__item {
      margin-left: .5em;
      margin-right: .5em;
      .social__link {
        color: $color-white;
        display: flex;
        overflow: hidden;
        padding-bottom: .2em;
        padding-top: .2em;
        text-indent: -9999px;
        &::after {
          background-repeat: no-repeat;
          background-size: contain;
          content: '';
          display: block;
          height: 1.5em;
          transition: all $transition-default;
          width: 1.5em;
        }
        &.facebook::after {
          background-image: url('../images/icons/icon-fb-active.svg');
          @include breakpoint($normal) {
            background-image: url('../images/icons/icon-fb-inactive.svg');
          }
        }
        &.instagram::after {
          background-image: url('../images/icons/icon-ig-active.svg');
          @include breakpoint($normal) {
            background-image: url('../images/icons/icon-ig-inactive.svg');
          }
        }
        &:hover {
          &::after {
            transform: translateY(-.075em);
          }
        }
      }
    }
  }
  &.social-footer {
    .social__menu .social__item .social__link {
      &.facebook::after {
        background-image: url('../images/icons/icon-fb-small-inactive.svg');
      }
      &.instagram::after {
        background-image: url('../images/icons/icon-ig-small-inactive.svg');
      }
    }
  }
}

.sticky {
  .social__menu .social__item .social__link {
    &.facebook::after {
      background-image: url('../images/icons/icon-fb-active.svg');
    }
    &.instagram::after {
      background-image: url('../images/icons/icon-ig-active.svg');
    }
  }
}