/*******************/
/* _c-review.scss */
/*****************/

.review {
  padding: 1em;
  &:not(:last-of-type) {
    border-bottom: 1px solid rgba($color-gray-light, .35);
  }
  @include breakpoint($small) {
    display: flex;
  }
  .review__left {
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
    min-width: 6.5em;
    .review__profile {
      display: none;
      @include breakpoint($small) {
        display: block;
        text-align: center;
      }
      .review__photo {
        margin: .25em auto .5em;
      }
      .review__name {
        color: $color-gray-light;
        font-size: $font-small;
        font-weight: 700;
      }
    }
    .review__responsive {
      display: flex;
      justify-content: space-between;
      width: 100%;
      @include breakpoint($small) {
        display: none;
      }
      .review__responsive-wrapper {
        .review__name {
          color: $color-gray-light;
          font-size: $font-small;
          font-weight: 700;
        }
        .review__date {
          font-size: $font-small;
          font-weight: 700;
        }
      }
      .review__rate {
        align-items: center;
        color: $color-turquoise;
        display: flex;
        font-size: $font-small;
        min-width: 3em;
        .review__current-rate {
          font-weight: 700;
          margin-right: .2em;
        }
      }
    }
  }
  .review__right {
    align-items: center;
    display: flex;
    justify-content: center;
    @include breakpoint($small) {
      padding: 1em;
    }
    .review__info {
      @include breakpoint($small) {
        padding: .5em;
      }
      .review__date {
        display: none;
        @include breakpoint($small) {
          display: block;
          font-size: $font-small;
          font-weight: 700;
        }
      }
      .review__description {
        font-size: $font-small;
        margin-top: .5em;
        @include breakpoint($small) {
          font-size: $font-normal;
        }
      }
    }
    .review__rate {
      display: none;
      @include breakpoint($small) {
        align-items: center;
        color: $color-turquoise;
        display: flex;
        min-width: 3em;
      }
      .review__current-rate {
        font-size: $font-discrete;
        font-weight: 700;
        margin-right: .2em;
      }
    }
  }
}