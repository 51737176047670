h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-primary;
  margin: 0;
  padding-bottom: .5rem;
  padding-top: .5rem;
  strong {
    color: inherit;
  }
}

h1 {

}

h2 {

}

h3 {

}

h4 {

}

h5 {

}

h6 {

}

a {
  &:hover {

  }
}