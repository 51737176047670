/******************/
/* _c-phone.scss */
/****************/

.phone-wrapper {
  align-items: center;
  display: flex;
  .phone-icon {
    @include sprite--icon-phone;
  }
  .phone-cta {
    cursor: pointer;
    font-family: $font-secondary;
    font-size: $font-small;
    margin-left: .35em;
    text-decoration: underline;
    @include breakpoint($xsmall + 1) {
      font-size: pxrem(15);
    }
    @include breakpoint($large + 80) {
      font-size: $font-normal;
    }
  }
  .phone-number {
    font-family: $font-secondary;
    font-size: $font-small;
    margin-left: .35em;
    /*opacity: 0;*/
    transition: opacity $transition-default;
    width: 0;
    @include breakpoint($xsmall + 1) {
      font-size: pxrem(15);
    }
    @include breakpoint($large + 80) {
      font-size: $font-normal;
    }
  }
  &.open {
    .phone-cta {
      display: none;
    }
    .phone-number {
      opacity: 1;
    }
  }
}