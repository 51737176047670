* {
  box-sizing: border-box;
}

*:focus,
*:active {
  outline: 0;
}

html {
  height: 100%;
  min-height: 100%;
  width: 100%;
}

body {
  @include fontSmoothing;
  background-color: $color-white;
  color: $color-gray;
  font-family: $font-primary;
  font-size: $font-normal;
  height: 100%;
  line-height: 1.3;
  min-height: 100%;
  overflow-x: hidden;
  width: 100%;
}

img, media, svg {
  display: block;
  height: auto;
  max-width: 100%;
}

ul, ol {
  margin: 0;
}

button {
  border: 0;
  background: transparent;
  font-family: $font-primary;
  cursor: pointer;
  padding: 0;
  &:focus {
    outline: none;
  }
}

button,
input[type="submit"],
input[type="button"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

/* Chrome autocomplete form fix */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset;
}

/* IE10+ specific styles */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html {
    height: auto;
  }
}

/* Safari 9 specific styles */
@supports (overflow:-webkit-marquee)
   and (justify-content:inherit) {
    html {
      height: auto;
    }
}