/********************/
/* _c-filters.scss */
/******************/

.filters {
  background-color: $color-gray-lightest;
  padding: .5em .2em;
  @include breakpoint($small + 1) {
    padding: 1em;
  }
  .filters__wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    .filters__left {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
      @include breakpoint($normal + 1) {
        border-right: 1px solid rgba($color-gray-light, .35);
        flex-wrap: no-wrap;
        padding-right: 1em;
        width: 50%;
      }
      .filters__place {
        align-items: flex-end;
        display: flex;
        .filters__place-label {
          display: none;
          @include breakpoint($normal + 1) {
            display: block;
            font-weight: 700;
            line-height: 1.5;
          }
        }
        .filters__place-value {
          align-items: center;
          color: $color-turquoise;
          cursor: pointer;
          display: flex;
          font-size: $font-normal;
          font-weight: 700;
          line-height: 1;
          margin-left: .125em;
          @include breakpoint($xsmall) {
            font-size: $font-intermediate;
          }
          @include breakpoint($small) {
            font-size: $font-xlarge;
          }
          @include breakpoint($normal + 1) {
            font-size: $font-xxlarge;
          }
          &::after {
            @include sprite--icon-chevron-down-yellow;
            content: '';
            display: block;
            margin-left: -.1em;
            margin-top: .15em;
            transform: scale(.75);
            @include breakpoint($small) {
              margin-left: .1em;
              transform: scale(1);
            }
          }
        }
      }
      .filters__button {
        background-color: $color-white;
        border: 1px solid $color-gray-lighter;
        border-radius: 6px;
        cursor: pointer;
        display: inline-block;
        font-size: $font-xsmall;
        padding: .35em .4em;
        transition: all $transition-default;
        @include breakpoint($normal + 1) {
          display: none;
        }
        span {
          font-weight: 700;
        }
        &.active {
          background-color: $color-turquoise;
          color: $color-white;
        }
      }
    }
    .filters__right {
      background-color: $color-white;
      border-radius: 6px;
      box-shadow: 0 0 8px 0 rgba(0,0,0,.17);
      left: 0;
      margin: 0 auto;
      max-width: 25em;
      opacity: 0;
      padding: 1.25em;
      pointer-events: none;
      position: fixed;
      right: 0;
      top: 8em;
      transition: all $transition-default;
      width: 95%;
      z-index: -1;
      &.open {
        opacity: 1;
        pointer-events: all;
        z-index: 2;
      }
      @include breakpoint($normal + 1) {
        background-color: rgba(0, 0, 0, 0);
        border-radius: 0;
        box-shadow: none;
        max-width: 100%;
        opacity: 1;
        padding: 0 1em 0 2em;
        pointer-events: all;
        position: static;
        width: 50%;
        z-index: 0;
      }
      @include breakpoint($large) {
        padding-left: 6em;
      }
      .filters__rooms {
        text-align: center;
        @include breakpoint($normal + 1) {
          align-items: center;
          display: flex;
          text-align: left;
        }
        .filters__room-label {
          display: none;
          @include breakpoint($normal + 1) {
            display: block;
            font-weight: 700;
            margin-right: .5em;
          }
        }
        .filters__room-label-mobile {
          color: $color-gray-light;
          font-size: $font-small;
          font-weight: 700;
          @include breakpoint($normal + 1) {
            display: none;
          }
        }
        .filters__rooms-wrapper {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin-top: 1.5em;
          @include breakpoint($normal + 1) {
            flex-wrap: nowrap;
            justify-content: flex-start;
            margin-top: 0;
          }
          .filter__rooms-item {
            cursor: pointer;
            margin-bottom: 1.2em;
            margin-left: 1em;
            margin-right: 1em;
            user-select: none;
            @include breakpoint($normal + 1) {
              margin-bottom: 0;
              margin-left: .5em;
              margin-right: .5em;
            }
            .filter__rooms-item-image {
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              height: 4em;
              margin: 0 auto .2em;
              transition: background-image $transition-default;
              user-select: none;
              width: 4em;
              @include breakpoint($normal + 1) {
                height: 3.25em;
                width: 3.25em;
              }
              &.cucina {
                background-image: url('../images/icons/rooms/icon-cucina-inactive.svg');
              }
              &.camera {
                background-image: url('../images/icons/rooms/icon-camera-inactive.svg');
              }
              &.bagno {
                background-image: url('../images/icons/rooms/icon-bagno-inactive.svg');
              }
              &.living {
                background-image: url('../images/icons/rooms/icon-living-inactive.svg');
              }
              &.cameretta {
                background-image: url('../images/icons/rooms/icon-cameretta-inactive.svg');
              }
            }
            .filter__rooms-item-label {
              font-size: $font-xsmall;
              font-weight: 700;
              text-align: center;
              text-transform: uppercase;
            }
            &.active {
              .filter__rooms-item-image.cucina {
                background-image: url('../images/icons/rooms/icon-cucina-active.svg');
              }
              .filter__rooms-item-image.camera {
                background-image: url('../images/icons/rooms/icon-camera-active.svg');
              }
              .filter__rooms-item-image.bagno {
                background-image: url('../images/icons/rooms/icon-bagno-active.svg');
              }
              .filter__rooms-item-image.living {
                background-image: url('../images/icons/rooms/icon-living-active.svg');
              }
              .filter__rooms-item-image.cameretta {
                background-image: url('../images/icons/rooms/icon-cameretta-active.svg');
              }
            }
          }
        }
        .filters__room-cta {
          @include button-full;
          margin: .5em auto;
          padding: .35em 1.5em;
          text-transform: none;
          @include breakpoint($normal + 1) {
            display: none;
          }
        }
      }
    }
  }
}