.process {

  display: flex;
  justify-content: center;
  margin: 1em auto 2em;
  max-width: 26em;
  .process-step {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: center;
    .process-step__number {
      align-items: center;
      background-color: $color-gray-lighter;
      border-radius: 50%;
      color: $color-white;
      display: flex;
      font-weight: 700;
      justify-content: center;
      height: 1.5em;
      transition: background-color $transition-default;
      width: 1.5em;
    }
    .process-step__description {
      color: $color-gray-lighter;
      font-weight: 700;
      margin-top: .5em;
      text-transform: uppercase;
      transition: color $transition-default;
    }
    &.active {
      .process-step__number {
        background-color: $color-yellow;
      }
      .process-step__description {
        color: $color-yellow;
      }
    }
  }
  .divider {
    background: $color-gray-lighter;
    height: 2px;
    margin-left: .75em;
    margin-right: .75em;
    margin-top: .75em;
    width: 100%;
    &.divider-2 {
      display: none;
    }
  }
}