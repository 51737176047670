/**************************/
/* _l-section-shops.scss */
/************************/

.section__shops {
  column-count: 2;
  column-gap: 2em;
  margin-bottom: 1.5em;
  margin-top: 1em;
  @include breakpoint($small) {
    column-count: 3;
  }
  @include breakpoint($medium) {
    column-count: 4;
  }
  @include breakpoint($normal) {
    column-count: 6;
  }
}