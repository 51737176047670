/***********************/
/* _c-eval-stars.scss */
/*********************/

.eval {
  .eval__stars {
    display: flex;
    justify-content: flex-end;
    margin-top: .75em;
    .eval__star {
      @include sprite--icon-star-empty;
      margin-left: -.182em;
      margin-right: -.182em;
      z-index: 2;
      &.full {
        @include sprite--icon-star-full;
      }
      // &.selectable:hover {
      //   @include sprite--icon-star-full;
      //   opacity: .25;
      // }
    }
  }
  .eval__nreviews {
    color: $color-gray-light;
    font-size: $font-xsmall;
    font-weight: 700;
  }
  &.left {
    .eval__stars {
      justify-content: flex-start;
      margin-top: 0;
    }
  }
}