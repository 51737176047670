/*************************/
/* _c-search-modal.scss */
/***********************/

.search-modal {
  background-color: $color-turquoise;
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: all $transition-default;
  z-index: -1;
  &.show {
    opacity: 1;
    pointer-events: all;
    z-index: 2;
  }
  .search-modal__wrapper {
    align-items: center;
    background-color: $color-white;
    border-radius: 8px;
    bottom: 2em;
    box-shadow: 0 0 8px 0 rgba($color-black,.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 2em;
    position: absolute;
    right: 2em;
    top: 2em;
    .search-modal__close {
      @include sprite--icon-close;
      cursor: pointer;
      position: absolute;
      right: -1em;
      top: -1em;
    }
    .search-modal__title {
      color: $color-turquoise;
      font-size: $font-discrete;
      font-weight: 700;
      margin-bottom: .5em;
      text-align: center;
      @include breakpoint($xsmall + 1) {
        font-size: $font-intermediate;
      }
      @include breakpoint($small + 1) {
        align-items: center;
        display: flex;
        font-size: $font-large;
      }
      @include breakpoint($normal + 1) {
        font-size: $font-xxlarge;
      }
      &::before {
        @include sprite--icon-pin-small;
        content: '';
        display: block;
        margin: 0 auto;
        @include breakpoint($small + 1) {
          margin-right: 0 .25em 0 0;
        }
      }
    }
    .search-modal__search {
      margin-top: 1.5em;
      position: relative;
      width: 85%;
      @include breakpoint($small + 1) {
        width: 75%;
      }
      @include breakpoint($normal + 1) {
        min-width: 42em;
        width: auto;
      }
      .search-modal__box {
        border-bottom: 2px solid $color-yellow;
        color: $color-gray-lighter;
        font-size: $font-large;
        font-weight: 700;
        padding: .012em .25em;
        @include breakpoint($xsmall + 1) {
          font-size: $font-xxlarge;
        }
        @include breakpoint($small + 1) {
          font-size: $font-xxxlarge;
        }
        @include breakpoint($normal + 1) {
          font-size: $font-huge;
        }
        &.selected {
          color: $color-gray;
        }
      }
      .search-modal__list {
        background-color: $color-white;
        border-radius: 8px;
        box-shadow: 0 0 8px 0 rgba($color-black,.2);
        left: 0;
        list-style: none;
        height: 0;
        max-height: 10em;
        overflow-y: auto;
        padding: 0;
        position: absolute;
        right: 0;
        transition: all $transition-default;
        top: 5.5em;
        &.open {
          height: auto;
          padding: .75em 1em;
        }
        .search-modal__item {
          cursor: pointer;
          font-family: $font-secondary;
          font-size: $font-normal;
          font-weight: 700;
          padding-left: .35em;
          @include breakpoint($xsmall + 1) {
            font-size: $font-medium;
          }
          @include breakpoint($small + 1) {
            font-size: $font-discrete;
          }
          &:hover {
            background-color: rgba($color-turquoise, .125);
          }
          &.item-region {
            font-size: $font-small;
            font-weight: 400;
            margin-top: 1.25em;
            padding-left: 0;
            pointer-events: none;
          }
        }
      }
      .search-modal__suggestion {
        font-size: $font-small;
        margin-top: .35em;
        text-align: right;
        text-transform: uppercase;
      }
    }
  }
}