.proposal-form {
  max-width: 25em;
  margin: 0 auto;
  .proposal-form__input-wrapper {
    margin-bottom: 1em;
    margin-top: 1em;
    .proposal-form__label {
      display: block;
      font-size: $font-small;
    }
    .proposal-form__input {
      color: $color-gray;
      border: 2px solid $color-gray-lighter;
      border-radius: 4px;
      font-family: $font-primary;
      line-height: 1.65em;
      margin-top: .25em;
      padding-left: .3em;
      width: 100%;
    }
  }
  .proposal-form__select-wrapper {
    margin-bottom: 1em;
    margin-top: 1em;
    @include breakpoint($small) {
      align-items: center;
      display: flex;
    }
    .proposal-form__label {
      display: block;
      flex-shrink: 0;
      font-size: $font-small;
      margin-right: .5em;
    }
    .proposal-form__select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: $color-white;
      background-image: url('../images/icons/icon-chevron-down-yellow.svg');
      background-position: 97%;
      background-repeat: no-repeat;
      background-size: 1em;
      color: $color-gray;
      border: 2px solid $color-gray-lighter;
      border-radius: 4px;
      font-family: $font-primary;
      height: 2em;
      margin-top: .25em;
      padding-left: .3em;
      width: 100%;
    }
  }
  .proposal-form__checkbox-wrapper {
    align-items: center;
    display: flex;
    margin-bottom: 1em;
    margin-top: 1em;
    .proposal-form__label {
      display: block;
      font-size: $font-small;
      margin-left: .5em;
      a {
        color: $color-gray;
      }
    }
    .proposal-form__select {

    }
  }
  .proposal-form__submit {
    margin-top: 3em;
    text-align: center;
    input {
      @include button-full-small;
    }
  }
}