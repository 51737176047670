/***************************/
/* _l-component-shop.scss */
/*************************/

.shop {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  &:not(:first-of-type) {
    margin-top: 1em;
  }
  &::before {
    background-color: rgba($color-gray-light, .25);
    content: '';
    display: block;
    height: 1px;
    widows: 100%;
  }
  .shop__title {
    color: $color-turquoise;
    font-size: $font-normal;
    font-weight: 700;
    padding-bottom: 0;
    @include breakpoint($xsmall) {
      font-size: $font-medium;
    }
    @include breakpoint($small) {
      font-size: $font-discrete;
    }
  }
  .shop__list {
    list-style: none;
    padding-left: 0;
    .shop__item {
      .shop__link {
        color: $color-gray;
        font-size: $font-small;
        text-decoration: none;
        transition: color $transition-default;
        @include breakpoint($xsmall) {
          font-size: $font-normal;
        }
        &:hover {
          color: $color-gray-light;
        }
      }
    }
  }
}