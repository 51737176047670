/********************************/
/* _l-section-outlet-best.scss */
/******************************/

.section__outlet-best {
  background-color: $color-turquoise;
  padding: 3em 1em;
  text-align: center;
  .outlet-best__title {
    color: $color-white;
    font-size: $font-discrete;
    font-weight: 400;
    @include breakpoint($xsmall + 1) {
      font-size: $font-intermediate;
    }
    @include breakpoint($medium + 1) {
      font-size: $font-xxlarge;
    }
  }
  .outlet-best__image {
    align-content: center;
    display: flex;
    justify-content: center;
    > div {
      margin: 1.25em .35em;
      max-width: 10.6em;
      width: 30%;
      will-change: transform;
    }
  }
  .outlet-best__cta {
    @include button-full('true');
    margin-top: .6em;
    will-change: transform;
    &::before {
      @include sprite--icon-list;
    }
  }
  .outlet-best__description {
    color: $color-white;
    font-size: $font-normal;
    font-weight: 700;
    margin: .75em auto;
    max-width: 15em;
    will-change: transform;
    @include breakpoint($xsmall + 1) {
      font-size: $font-discrete;
    }
    @include breakpoint($medium + 1) {
      font-size: $font-large;
    }
    span {
      color: $color-yellow;
      font-size: $font-medium;
      text-transform: uppercase;
      @include breakpoint($xsmall + 1) {
        font-size: $font-intermediate;
      }
      @include breakpoint($medium + 1) {
        font-size: $font-xlarge;
      }
    }
  }
}