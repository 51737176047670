/*******************************/
/* _l-section-detailhero.scss */
/*****************************/

.detail-hero {
  position: relative;
  overflow: hidden;
  .detail-hero__gallery {
    height: 32vh;
    position: relative;
    z-index: 1;
    @include breakpoint($small) {
      height: 50vh;
    }
    .detail-hero__gallery-content {
      height: 100%;
      .slick-list {
        height: 100%;
        .slick-track {
          height: 100%;
          .slick-slide {
            height: 100%;
            div {
              height: 100%;
              margin-left: .125em;
              margin-right: .125em;
              .detail-hero__gallery-item {
                height: 100%;
                width: auto !important;
              }
            }
          }
        }
      }
      .slick-arrow {
        position: absolute;
        text-indent: -9999px;
        top: 50%;
        transform: translateY(-50%) scale(.75);
        z-index: 2;
        @include breakpoint($small + 1) {
          transform: translateY(-50%);
        }
        &::after {
          content: '';
          display: block;
        }
        &.slick-prev {
          left: 0;
          &::after {
            @include sprite--icon-carousel-left-white;
          }
        }
        &.slick-next {
          right: 0;
          &::after {
            @include sprite--icon-carousel-right-white;
          }
        }
      }
    }
  }
  .detail-hero__discount {
    align-items: stretch;
    bottom: 0;
    display: inline-flex;
    height: 3em;
    left: 0;
    position: absolute;
    z-index: 2;
    @include breakpoint($small) {
      height: 3.35em;
    }
    @include breakpoint($medium) {
      height: 4em;
    }
    &::after {
      background-image: url('../images/elements/discount-flag.svg');
      background-position: left;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      display: block;
      height: 102%;
      margin-left: -1px;
      width: 1.5em;
    }
    .detail-hero__discount-wrapper {
      background-color: $color-yellow;
      padding: .25em .5em;
      text-align: center;
      .detail-hero__discount-description {
        font-size: $font-xsmall;
        font-weight: 700;
        line-height: 1;
        @include breakpoint($small) {
          font-size: $font-small;
        }
      }
      .detail-hero__discount-value {
        color: $color-white;
        font-size: $font-large;
        font-weight: 700;
        line-height: 1;
        @include breakpoint($small) {
          font-size: $font-xlarge;
        }
        @include breakpoint($medium) {
          font-size: $font-xxlarge;
        }
      }
    }
  }
}