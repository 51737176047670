@import url("https://fonts.googleapis.com/css?family=Quicksand:400,700|Roboto:300,400,500,700&display=swap");
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

body {
  margin: 0; }

article,
aside,
footer,
header,
nav,
section {
  display: block; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

figcaption,
figure,
main {
  /* 1 */
  display: block; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

b,
strong {
  font-weight: inherit; }

b,
strong {
  font-weight: bolder; }

code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

dfn {
  font-style: italic; }

mark {
  background-color: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

audio,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

button,
input {
  /* 1 */
  overflow: visible; }

button,
select {
  /* 1 */
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */ }

progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

details,
menu {
  display: block; }

summary {
  display: list-item; }

canvas {
  display: inline-block; }

template {
  display: none; }

[hidden] {
  display: none; }

* {
  box-sizing: border-box; }

*:focus,
*:active {
  outline: 0; }

html {
  height: 100%;
  min-height: 100%;
  width: 100%; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF;
  color: #4A4A4A;
  font-family: "Quicksand", sans-serif;
  font-size: 1rem;
  height: 100%;
  line-height: 1.3;
  min-height: 100%;
  overflow-x: hidden;
  width: 100%; }

img, media, svg {
  display: block;
  height: auto;
  max-width: 100%; }

ul, ol {
  margin: 0; }

button {
  border: 0;
  background: transparent;
  font-family: "Quicksand", sans-serif;
  cursor: pointer;
  padding: 0; }
  button:focus {
    outline: none; }

button,
input[type="submit"],
input[type="button"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit; }

/* Chrome autocomplete form fix */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset; }

/* IE10+ specific styles */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html {
    height: auto; } }

/* Safari 9 specific styles */
@supports (overflow: -webkit-marquee) and (justify-content: inherit) {
  html {
    height: auto; } }

.form__element-label {
  display: block;
  font-size: 0.875rem; }

.form__element-input {
  background: #FFFFFF;
  border: 2px solid #E5E5E5;
  border-radius: 4px;
  font-family: "Quicksand", sans-serif;
  line-height: 1.35;
  padding: .25em; }
  .form__element-input.textarea {
    min-height: 8em;
    resize: none; }

.form-wrapper {
  width: 100%; }
  .form-wrapper .form__element-wrapper {
    margin-bottom: .5em;
    width: 100%; }
    @media (min-width: 640px) {
      .form-wrapper .form__element-wrapper {
        margin: .5em;
        width: calc(50% - 1em); } }
    .form-wrapper .form__element-wrapper.wide {
      width: 100%; }
      @media (min-width: 640px) {
        .form-wrapper .form__element-wrapper.wide {
          width: calc(100% - 1em); } }
    .form-wrapper .form__element-wrapper .form__element-input {
      width: 100%; }

.form__element-wrapper.form__checkbox .form__checkbox-container {
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  padding-left: 1.75em;
  position: relative;
  user-select: none; }
  .form__element-wrapper.form__checkbox .form__checkbox-container .form__element-input.checkbox {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0; }
  .form__element-wrapper.form__checkbox .form__checkbox-container .form__checkmark {
    background-color: #FFFFFF;
    border: 2px solid #E5E5E5;
    border-radius: 3px;
    height: 1.2em;
    left: 0;
    position: absolute;
    top: 0;
    width: 1.2em; }

/* On mouse-over, add a grey background color */
.form__checkbox-container:hover .form__element-input.checkbox ~ .form__checkmark {
  background-color: rgba(243, 243, 243, 0.125); }

/* When the checkbox is checked, add a blue background */
/* Create the checkmark/indicator (hidden when not checked) */
.form__checkmark:after {
  content: '';
  display: none;
  position: absolute; }

/* Show the checkmark when checked */
.form__checkbox-container .form__element-input.checkbox:checked ~ .form__checkmark:after {
  display: block; }

/* Style the checkmark/indicator */
.form__checkbox-container .form__checkmark:after {
  background-image: url("../images/elements/checkmark.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: .9em;
  left: .05em;
  top: .05em;
  width: .9em; }

.h-wrapper {
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
  width: 95%; }

.h-hide {
  display: none; }

.h-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.h-noscroll {
  overflow: hidden; }

.h-animation-hide {
  opacity: 0;
  visibility: hidden; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Quicksand", sans-serif;
  margin: 0;
  padding-bottom: .5rem;
  padding-top: .5rem; }
  h1 strong,
  h2 strong,
  h3 strong,
  h4 strong,
  h5 strong,
  h6 strong {
    color: inherit; }

/*****************************/
/* _c-breadcrumb-shops.scss */
/***************************/
.breadcrumb__shops {
  background-color: #F3F3F3; }
  .breadcrumb__shops .breadcrumb__shops-wrapper {
    padding-bottom: .2em;
    padding-top: .125em; }
    .breadcrumb__shops .breadcrumb__shops-wrapper .breadcrumb__shops-list {
      list-style: none;
      padding-left: 0; }
      .breadcrumb__shops .breadcrumb__shops-wrapper .breadcrumb__shops-list .breadcrumb__shops-item {
        display: inline-block; }
        .breadcrumb__shops .breadcrumb__shops-wrapper .breadcrumb__shops-list .breadcrumb__shops-item:not(:last-of-type)::after {
          content: '/';
          display: inline-block;
          font-size: 0.75rem;
          margin-left: .125em;
          margin-right: .125em; }
        .breadcrumb__shops .breadcrumb__shops-wrapper .breadcrumb__shops-list .breadcrumb__shops-item .breadcrumb__shops-link {
          color: #4A4A4A;
          font-size: 0.75rem;
          font-weight: 700;
          text-decoration: none;
          transition: color 220ms ease-out; }

/***********************/
/* _c-breadcrumb.scss */
/*********************/
.breadcrumb.detail-header__breadcrumb .breadcrumb__wrapper {
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  padding-bottom: .35em;
  padding-top: .35em; }
  .breadcrumb.detail-header__breadcrumb .breadcrumb__wrapper .breadcrumb__list {
    align-items: center;
    display: flex;
    list-style: none;
    padding-left: 0; }
    .breadcrumb.detail-header__breadcrumb .breadcrumb__wrapper .breadcrumb__list .breadcrumb__item {
      align-items: center;
      display: flex; }
      .breadcrumb.detail-header__breadcrumb .breadcrumb__wrapper .breadcrumb__list .breadcrumb__item:not(:last-of-type)::after {
        content: '/';
        display: block;
        font-size: 0.75rem;
        margin-left: .35em;
        margin-right: .35em; }
        @media (min-width: 640px) {
          .breadcrumb.detail-header__breadcrumb .breadcrumb__wrapper .breadcrumb__list .breadcrumb__item:not(:last-of-type)::after {
            margin-left: .65em;
            margin-right: .65em; } }
      .breadcrumb.detail-header__breadcrumb .breadcrumb__wrapper .breadcrumb__list .breadcrumb__item .breadcrumb__link {
        color: #4A4A4A;
        font-size: 0.75rem;
        font-weight: 700;
        text-decoration: none;
        transition: color 220ms ease-out; }
        .breadcrumb.detail-header__breadcrumb .breadcrumb__wrapper .breadcrumb__list .breadcrumb__item .breadcrumb__link:hover {
          color: #9B9B9B; }
        .breadcrumb.detail-header__breadcrumb .breadcrumb__wrapper .breadcrumb__list .breadcrumb__item .breadcrumb__link.current {
          color: #9B9B9B; }

/**************************/
/* _c-card-designer.scss */
/************************/
.designer-card {
  margin: .5em;
  width: 20rem; }
  @media (min-width: 1350px) {
    .designer-card {
      width: 25rem; } }
  .designer-card.fluid {
    max-width: 25rem;
    width: calc(100% - 1em); }
    @media (min-width: 768px) {
      .designer-card.fluid {
        max-width: 100%;
        width: calc(50% - 2em); } }
  .designer-card .designer-card__wrapper {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.17);
    border-radius: 10px;
    height: 100%;
    overflow: hidden;
    padding: 0 0 24px;
    position: relative; }
    .designer-card .designer-card__wrapper .designer-card__internal-wrapper {
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      height: 100%;
      max-width: 100%;
      position: relative; }
      .designer-card .designer-card__wrapper .designer-card__internal-wrapper::before {
        background-color: #FFFFFF;
        content: "";
        height: 25px;
        left: 0;
        position: absolute;
        top: 100%;
        width: calc(100% - 25px); }
      .designer-card .designer-card__wrapper .designer-card__internal-wrapper::after {
        background-image: url("../images/elements/box_cut.png");
        background-repeat: no-repeat;
        background-size: cover;
        content: "";
        height: 25px;
        left: 100%;
        margin-left: -25px;
        position: absolute;
        top: 100%;
        width: 25px; }
      .designer-card .designer-card__wrapper .designer-card__internal-wrapper .designer-card__header {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 12.5rem;
        position: relative; }
        @media (min-width: 1025px) {
          .designer-card .designer-card__wrapper .designer-card__internal-wrapper .designer-card__header {
            height: 15.625rem; } }
        .designer-card .designer-card__wrapper .designer-card__internal-wrapper .designer-card__header .designer-card__discount {
          align-items: stretch;
          bottom: 0;
          display: flex;
          left: 0;
          position: absolute; }
          .designer-card .designer-card__wrapper .designer-card__internal-wrapper .designer-card__header .designer-card__discount span {
            align-items: center;
            background-color: #FFC244;
            display: flex;
            font-size: 0.875rem;
            padding: .25em .5em; }
          .designer-card .designer-card__wrapper .designer-card__internal-wrapper .designer-card__header .designer-card__discount::after {
            background-image: url("../images/elements/discount-flag.svg");
            background-position: left;
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
            display: block;
            height: 1.65em;
            margin-left: -1px;
            width: 1.25em; }
      .designer-card .designer-card__wrapper .designer-card__internal-wrapper .designer-card__body {
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: .5em .75em; }
        .designer-card .designer-card__wrapper .designer-card__internal-wrapper .designer-card__body .designer-card__title {
          color: #37A49A;
          font-size: 1rem;
          padding-bottom: .25em; }
          @media (min-width: 461px) {
            .designer-card .designer-card__wrapper .designer-card__internal-wrapper .designer-card__body .designer-card__title {
              font-size: 1.125rem; } }
          @media (min-width: 1025px) {
            .designer-card .designer-card__wrapper .designer-card__internal-wrapper .designer-card__body .designer-card__title {
              font-size: 1.1875rem; } }
          @media (min-width: 1280px) {
            .designer-card .designer-card__wrapper .designer-card__internal-wrapper .designer-card__body .designer-card__title {
              font-size: 1.25rem; } }
        .designer-card .designer-card__wrapper .designer-card__internal-wrapper .designer-card__body .designer-card__address {
          font-size: 0.875rem;
          font-weight: 700; }
          @media (min-width: 461px) {
            .designer-card .designer-card__wrapper .designer-card__internal-wrapper .designer-card__body .designer-card__address {
              font-size: 0.9375rem; } }
          @media (min-width: 1280px) {
            .designer-card .designer-card__wrapper .designer-card__internal-wrapper .designer-card__body .designer-card__address {
              font-size: 1rem; } }
        .designer-card .designer-card__wrapper .designer-card__internal-wrapper .designer-card__body .designer-card__footer {
          display: flex;
          justify-content: space-between;
          margin-top: auto;
          padding-bottom: .5em;
          padding-top: 2em;
          text-align: left; }
          .designer-card .designer-card__wrapper .designer-card__internal-wrapper .designer-card__body .designer-card__footer .designer-card__cta {
            background-color: transparent;
            border: 1px solid #FFC244;
            border-radius: 6.25em;
            color: #4A4A4A;
            cursor: pointer;
            display: inline-block;
            font-weight: 700;
            font-size: 0.875rem;
            line-height: 1.5;
            padding: .6em 1.75em;
            position: relative;
            text-decoration: none;
            text-transform: uppercase;
            transition: background-color 220ms ease-out;
            padding-right: 2.5em;
            padding: .4em 1em; }
            .designer-card .designer-card__wrapper .designer-card__internal-wrapper .designer-card__body .designer-card__footer .designer-card__cta:hover {
              background-color: rgba(255, 194, 68, 0.125);
              border-color: #ffb111; }
            .designer-card .designer-card__wrapper .designer-card__internal-wrapper .designer-card__body .designer-card__footer .designer-card__cta::before {
              content: '';
              display: block;
              right: 1em;
              top: 50%;
              transform: translateY(-50%);
              position: absolute; }
            @media (min-width: 641px) {
              .designer-card .designer-card__wrapper .designer-card__internal-wrapper .designer-card__body .designer-card__footer .designer-card__cta {
                padding: .6em 2.75em; }
                .designer-card .designer-card__wrapper .designer-card__internal-wrapper .designer-card__body .designer-card__footer .designer-card__cta::before {
                  background-image: url("../images/sprite.svg");
                  background-repeat: no-repeat;
                  background-position: 93.19371727748691% 90%;
                  width: 17px;
                  height: 22px;
                  content: '';
                  display: block; } }
  .designer-card.showcase .designer-card__header::after {
    /*background-image: url('../images/elements/showcase.svg');*/
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 4.5em;
    position: absolute;
    right: 0;
    top: 0;
    width: 4.5em; }

/************************/
/* _c-card-outlet.scss */
/**********************/
.outlet-card {
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #37A49A;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  margin-left: .5em;
  margin-right: .5em;
  overflow: hidden;
  width: 24em; }
  .outlet-card.fluid {
    min-width: 17.5em;
    width: calc(33% - 1em); }
    @media (min-width: 461px) {
      .outlet-card.fluid {
        min-width: 19em; } }
    @media (min-width: 769px) {
      .outlet-card.fluid {
        min-width: 24em; } }
  .outlet-card .outlet-card__header {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 9em; }
    @media (min-width: 640px) {
      .outlet-card .outlet-card__header {
        height: 12em; } }
    @media (min-width: 768px) {
      .outlet-card .outlet-card__header {
        height: 15em; } }
  .outlet-card .outlet-card__body {
    background-color: #F3F3F3;
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
    .outlet-card .outlet-card__body .outlet-card__shop {
      background-color: #37A49A;
      color: #FFFFFF;
      font-size: 0.875rem;
      font-weight: 700;
      padding: .275em .65em; }
    .outlet-card .outlet-card__body .outlet-card__top {
      flex-grow: 1;
      padding: .35em .75em; }
      .outlet-card .outlet-card__body .outlet-card__top .outlet-card__title {
        color: #37A49A;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1; }
        @media (min-width: 640px) {
          .outlet-card .outlet-card__body .outlet-card__top .outlet-card__title {
            font-size: 1.25rem; } }
        @media (min-width: 768px) {
          .outlet-card .outlet-card__body .outlet-card__top .outlet-card__title {
            font-size: 1.5rem; } }
      .outlet-card .outlet-card__body .outlet-card__top .outlet-card__description {
        font-size: 0.875rem; }
        @media (min-width: 768px) {
          .outlet-card .outlet-card__body .outlet-card__top .outlet-card__description {
            font-size: 1rem; } }
      .outlet-card .outlet-card__body .outlet-card__top .outlet-card__specs {
        font-size: 0.75rem;
        font-weight: 700;
        margin-top: .5em; }
    .outlet-card .outlet-card__body .outlet-card__bottom {
      align-items: flex-end;
      background-color: #FFFFFF;
      border-top: 1px solid rgba(155, 155, 155, 0.35);
      display: flex;
      justify-content: space-between;
      padding: .65em; }
      .outlet-card .outlet-card__body .outlet-card__bottom .outlet-card__bottom-left .outlet-card__price {
        font-weight: 700;
        text-decoration: line-through;
        font-size: 0.875rem; }
        @media (min-width: 640px) {
          .outlet-card .outlet-card__body .outlet-card__bottom .outlet-card__bottom-left .outlet-card__price {
            font-size: 1rem; } }
      .outlet-card .outlet-card__body .outlet-card__bottom .outlet-card__bottom-left .outlet-card__price-discounted {
        color: #37A49A;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1; }
        @media (min-width: 460px) {
          .outlet-card .outlet-card__body .outlet-card__bottom .outlet-card__bottom-left .outlet-card__price-discounted {
            font-size: 1.75rem; } }
        @media (min-width: 640px) {
          .outlet-card .outlet-card__body .outlet-card__bottom .outlet-card__bottom-left .outlet-card__price-discounted {
            font-size: 2rem; } }
        @media (min-width: 768px) {
          .outlet-card .outlet-card__body .outlet-card__bottom .outlet-card__bottom-left .outlet-card__price-discounted {
            font-size: 2.375rem; } }
      .outlet-card .outlet-card__body .outlet-card__bottom .outlet-card__bottom-right .outlet-card__cta {
        background-color: transparent;
        border: 1px solid #FFC244;
        border-radius: 6.25em;
        color: #4A4A4A;
        cursor: pointer;
        display: inline-block;
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 1.5;
        padding: .6em 1.75em;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        transition: background-color 220ms ease-out;
        padding-right: 2.5em;
        padding: .4em 1em;
        text-transform: none; }
        .outlet-card .outlet-card__body .outlet-card__bottom .outlet-card__bottom-right .outlet-card__cta:hover {
          background-color: rgba(255, 194, 68, 0.125);
          border-color: #ffb111; }
        .outlet-card .outlet-card__body .outlet-card__bottom .outlet-card__bottom-right .outlet-card__cta::before {
          content: '';
          display: block;
          right: 1em;
          top: 50%;
          transform: translateY(-50%);
          position: absolute; }
        @media (min-width: 641px) {
          .outlet-card .outlet-card__body .outlet-card__bottom .outlet-card__bottom-right .outlet-card__cta {
            padding: .45em 1.5em; }
            .outlet-card .outlet-card__body .outlet-card__bottom .outlet-card__bottom-right .outlet-card__cta::before {
              background-image: url("../images/sprite.svg");
              background-repeat: no-repeat;
              background-position: 93.19371727748691% 90%;
              width: 17px;
              height: 22px;
              content: '';
              display: block;
              opacity: .75;
              right: .35em; } }

.wpcc-container {
  border: 2px solid #37A49A;
  font-family: "Quicksand", sans-serif;
  font-size: 0.75rem;
  padding: 1em; }
  .wpcc-container.wpcc-bottom {
    bottom: 2em !important; }
  .wpcc-container.wpcc-right {
    right: 2em !important; }
    @media (max-width: 414px) {
      .wpcc-container.wpcc-right {
        left: 2em !important; } }
  .wpcc-container .wpcc-compliance {
    text-align: center; }
    .wpcc-container .wpcc-compliance .wpcc-btn {
      background-color: #FFC244;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
      border-radius: 6.25em;
      color: #FFFFFF;
      cursor: pointer;
      display: inline-block;
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1;
      padding: .6em 2.5em;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;
      transition: background-color 220ms ease-out;
      font-size: 0.75rem !important;
      text-transform: none; }
      @media (min-width: 641px) {
        .wpcc-container .wpcc-compliance .wpcc-btn {
          font-size: 1.25rem; } }
      .wpcc-container .wpcc-compliance .wpcc-btn:hover {
        background-color: #ffb111; }

/***********************/
/* _c-eval-stars.scss */
/*********************/
.eval .eval__stars {
  display: flex;
  justify-content: flex-end;
  margin-top: .75em; }
  .eval .eval__stars .eval__star {
    background-image: url("../images/sprite.svg");
    background-repeat: no-repeat;
    background-position: 100% 0;
    width: 30px;
    height: 29px;
    margin-left: -.182em;
    margin-right: -.182em;
    z-index: 2; }
    .eval .eval__stars .eval__star.full {
      background-image: url("../images/sprite.svg");
      background-repeat: no-repeat;
      background-position: 100% 20.27972027972028%;
      width: 30px;
      height: 29px; }

.eval .eval__nreviews {
  color: #9B9B9B;
  font-size: 0.75rem;
  font-weight: 700; }

.eval.left .eval__stars {
  justify-content: flex-start;
  margin-top: 0; }

/********************/
/* _c-filters.scss */
/******************/
.filters {
  background-color: #F3F3F3;
  padding: .5em .2em; }
  @media (min-width: 641px) {
    .filters {
      padding: 1em; } }
  .filters .filters__wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between; }
    .filters .filters__wrapper .filters__left {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%; }
      @media (min-width: 1025px) {
        .filters .filters__wrapper .filters__left {
          border-right: 1px solid rgba(155, 155, 155, 0.35);
          flex-wrap: no-wrap;
          padding-right: 1em;
          width: 50%; } }
      .filters .filters__wrapper .filters__left .filters__place {
        align-items: flex-end;
        display: flex; }
        .filters .filters__wrapper .filters__left .filters__place .filters__place-label {
          display: none; }
          @media (min-width: 1025px) {
            .filters .filters__wrapper .filters__left .filters__place .filters__place-label {
              display: block;
              font-weight: 700;
              line-height: 1.5; } }
        .filters .filters__wrapper .filters__left .filters__place .filters__place-value {
          align-items: center;
          color: #37A49A;
          cursor: pointer;
          display: flex;
          font-size: 1rem;
          font-weight: 700;
          line-height: 1;
          margin-left: .125em; }
          @media (min-width: 460px) {
            .filters .filters__wrapper .filters__left .filters__place .filters__place-value {
              font-size: 1.5rem; } }
          @media (min-width: 640px) {
            .filters .filters__wrapper .filters__left .filters__place .filters__place-value {
              font-size: 2rem; } }
          @media (min-width: 1025px) {
            .filters .filters__wrapper .filters__left .filters__place .filters__place-value {
              font-size: 2.375rem; } }
          .filters .filters__wrapper .filters__left .filters__place .filters__place-value::after {
            background-image: url("../images/sprite.svg");
            background-repeat: no-repeat;
            background-position: 0 100%;
            width: 30px;
            height: 23px;
            content: '';
            display: block;
            margin-left: -.1em;
            margin-top: .15em;
            transform: scale(0.75); }
            @media (min-width: 640px) {
              .filters .filters__wrapper .filters__left .filters__place .filters__place-value::after {
                margin-left: .1em;
                transform: scale(1); } }
      .filters .filters__wrapper .filters__left .filters__button {
        background-color: #FFFFFF;
        border: 1px solid #E5E5E5;
        border-radius: 6px;
        cursor: pointer;
        display: inline-block;
        font-size: 0.75rem;
        padding: .35em .4em;
        transition: all 220ms ease-out; }
        @media (min-width: 1025px) {
          .filters .filters__wrapper .filters__left .filters__button {
            display: none; } }
        .filters .filters__wrapper .filters__left .filters__button span {
          font-weight: 700; }
        .filters .filters__wrapper .filters__left .filters__button.active {
          background-color: #37A49A;
          color: #FFFFFF; }
    .filters .filters__wrapper .filters__right {
      background-color: #FFFFFF;
      border-radius: 6px;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.17);
      left: 0;
      margin: 0 auto;
      max-width: 25em;
      opacity: 0;
      padding: 1.25em;
      pointer-events: none;
      position: fixed;
      right: 0;
      top: 8em;
      transition: all 220ms ease-out;
      width: 95%;
      z-index: -1; }
      .filters .filters__wrapper .filters__right.open {
        opacity: 1;
        pointer-events: all;
        z-index: 2; }
      @media (min-width: 1025px) {
        .filters .filters__wrapper .filters__right {
          background-color: rgba(0, 0, 0, 0);
          border-radius: 0;
          box-shadow: none;
          max-width: 100%;
          opacity: 1;
          padding: 0 1em 0 2em;
          pointer-events: all;
          position: static;
          width: 50%;
          z-index: 0; } }
      @media (min-width: 1200px) {
        .filters .filters__wrapper .filters__right {
          padding-left: 6em; } }
      .filters .filters__wrapper .filters__right .filters__rooms {
        text-align: center; }
        @media (min-width: 1025px) {
          .filters .filters__wrapper .filters__right .filters__rooms {
            align-items: center;
            display: flex;
            text-align: left; } }
        .filters .filters__wrapper .filters__right .filters__rooms .filters__room-label {
          display: none; }
          @media (min-width: 1025px) {
            .filters .filters__wrapper .filters__right .filters__rooms .filters__room-label {
              display: block;
              font-weight: 700;
              margin-right: .5em; } }
        .filters .filters__wrapper .filters__right .filters__rooms .filters__room-label-mobile {
          color: #9B9B9B;
          font-size: 0.875rem;
          font-weight: 700; }
          @media (min-width: 1025px) {
            .filters .filters__wrapper .filters__right .filters__rooms .filters__room-label-mobile {
              display: none; } }
        .filters .filters__wrapper .filters__right .filters__rooms .filters__rooms-wrapper {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin-top: 1.5em; }
          @media (min-width: 1025px) {
            .filters .filters__wrapper .filters__right .filters__rooms .filters__rooms-wrapper {
              flex-wrap: nowrap;
              justify-content: flex-start;
              margin-top: 0; } }
          .filters .filters__wrapper .filters__right .filters__rooms .filters__rooms-wrapper .filter__rooms-item {
            cursor: pointer;
            margin-bottom: 1.2em;
            margin-left: 1em;
            margin-right: 1em;
            user-select: none; }
            @media (min-width: 1025px) {
              .filters .filters__wrapper .filters__right .filters__rooms .filters__rooms-wrapper .filter__rooms-item {
                margin-bottom: 0;
                margin-left: .5em;
                margin-right: .5em; } }
            .filters .filters__wrapper .filters__right .filters__rooms .filters__rooms-wrapper .filter__rooms-item .filter__rooms-item-image {
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              height: 4em;
              margin: 0 auto .2em;
              transition: background-image 220ms ease-out;
              user-select: none;
              width: 4em; }
              @media (min-width: 1025px) {
                .filters .filters__wrapper .filters__right .filters__rooms .filters__rooms-wrapper .filter__rooms-item .filter__rooms-item-image {
                  height: 3.25em;
                  width: 3.25em; } }
              .filters .filters__wrapper .filters__right .filters__rooms .filters__rooms-wrapper .filter__rooms-item .filter__rooms-item-image.cucina {
                background-image: url("../images/icons/rooms/icon-cucina-inactive.svg"); }
              .filters .filters__wrapper .filters__right .filters__rooms .filters__rooms-wrapper .filter__rooms-item .filter__rooms-item-image.camera {
                background-image: url("../images/icons/rooms/icon-camera-inactive.svg"); }
              .filters .filters__wrapper .filters__right .filters__rooms .filters__rooms-wrapper .filter__rooms-item .filter__rooms-item-image.bagno {
                background-image: url("../images/icons/rooms/icon-bagno-inactive.svg"); }
              .filters .filters__wrapper .filters__right .filters__rooms .filters__rooms-wrapper .filter__rooms-item .filter__rooms-item-image.living {
                background-image: url("../images/icons/rooms/icon-living-inactive.svg"); }
              .filters .filters__wrapper .filters__right .filters__rooms .filters__rooms-wrapper .filter__rooms-item .filter__rooms-item-image.cameretta {
                background-image: url("../images/icons/rooms/icon-cameretta-inactive.svg"); }
            .filters .filters__wrapper .filters__right .filters__rooms .filters__rooms-wrapper .filter__rooms-item .filter__rooms-item-label {
              font-size: 0.75rem;
              font-weight: 700;
              text-align: center;
              text-transform: uppercase; }
            .filters .filters__wrapper .filters__right .filters__rooms .filters__rooms-wrapper .filter__rooms-item.active .filter__rooms-item-image.cucina {
              background-image: url("../images/icons/rooms/icon-cucina-active.svg"); }
            .filters .filters__wrapper .filters__right .filters__rooms .filters__rooms-wrapper .filter__rooms-item.active .filter__rooms-item-image.camera {
              background-image: url("../images/icons/rooms/icon-camera-active.svg"); }
            .filters .filters__wrapper .filters__right .filters__rooms .filters__rooms-wrapper .filter__rooms-item.active .filter__rooms-item-image.bagno {
              background-image: url("../images/icons/rooms/icon-bagno-active.svg"); }
            .filters .filters__wrapper .filters__right .filters__rooms .filters__rooms-wrapper .filter__rooms-item.active .filter__rooms-item-image.living {
              background-image: url("../images/icons/rooms/icon-living-active.svg"); }
            .filters .filters__wrapper .filters__right .filters__rooms .filters__rooms-wrapper .filter__rooms-item.active .filter__rooms-item-image.cameretta {
              background-image: url("../images/icons/rooms/icon-cameretta-active.svg"); }
        .filters .filters__wrapper .filters__right .filters__rooms .filters__room-cta {
          background-color: #FFC244;
          box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
          border-radius: 6.25em;
          color: #FFFFFF;
          cursor: pointer;
          display: inline-block;
          font-weight: 700;
          font-size: 1.25rem;
          line-height: 1;
          padding: .6em 2.5em;
          position: relative;
          text-decoration: none;
          text-transform: uppercase;
          transition: background-color 220ms ease-out;
          margin: .5em auto;
          padding: .35em 1.5em;
          text-transform: none; }
          @media (min-width: 641px) {
            .filters .filters__wrapper .filters__right .filters__rooms .filters__room-cta {
              font-size: 1.5rem; } }
          .filters .filters__wrapper .filters__right .filters__rooms .filters__room-cta:hover {
            background-color: #ffb111; }
          @media (min-width: 1025px) {
            .filters .filters__wrapper .filters__right .filters__rooms .filters__room-cta {
              display: none; } }

.infobox {
  background-color: #FFFFFF;
  height: 100%;
  margin: 0 auto;
  max-width: 40em;
  padding: 2em 1.5em;
  position: relative; }
  @media (min-width: 640px) {
    .infobox {
      padding: 2em; } }
  .infobox::before {
    background-image: url("../images/landing/banner.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 8em;
    left: -.53em;
    position: absolute;
    top: -.53em;
    width: 8em; }
    @media (min-width: 640px) {
      .infobox::before {
        height: 11em;
        left: -.7175em;
        top: -.7175em;
        width: 11em; } }
  .infobox .infobox__title {
    color: #FFC244;
    font-size: 1.75rem;
    margin: 1em auto 0;
    max-width: 11em;
    text-align: center;
    text-transform: uppercase; }
    @media (min-width: 768px) {
      .infobox .infobox__title {
        font-size: 2rem; } }
  .infobox .infobox__process {
    display: none; }
    @media (min-width: 460px) {
      .infobox .infobox__process {
        display: flex; } }

/*****************/
/* _c-logo.scss */
/***************/
#main-logo {
  transition: all 220ms ease-out;
  width: 9em; }
  @media (min-width: 1024px) {
    #main-logo {
      width: auto; } }
  #main-logo .logo-negative {
    transition: opacity 220ms ease-out;
    opacity: 0; }
    @media (min-width: 1024px) {
      #main-logo .logo-negative {
        opacity: 1; } }

.sticky #main-logo {
  height: 2.75rem; }
  .sticky #main-logo .logo-negative {
    opacity: 0; }

/******************/
/* _c-modal.scss */
/****************/
.modal {
  background-color: rgba(55, 164, 154, 0.85);
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 220ms ease-out;
  z-index: -1; }
  .modal.show {
    opacity: 1;
    pointer-events: all;
    z-index: 2; }
  .modal .modal__wrapper {
    background-color: #FFFFFF;
    border-radius: 8px;
    bottom: 0;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    left: 0;
    margin: auto;
    max-height: 32em;
    max-width: 45em;
    position: absolute;
    right: 0;
    top: 0;
    width: 95%; }
    .modal .modal__wrapper .modal_header {
      align-items: center;
      border-bottom: 1px solid rgba(229, 229, 229, 0.5);
      display: flex;
      justify-content: space-between;
      padding: .75em 1.25em; }
      .modal .modal__wrapper .modal_header .modal__title {
        font-size: 1.75rem; }
      .modal .modal__wrapper .modal_header .modal__close {
        background-image: url("../images/sprite.svg");
        background-repeat: no-repeat;
        background-position: 0 83.33333333333333%;
        width: 34px;
        height: 34px;
        cursor: pointer;
        margin-right: -.5em; }

/***********************/
/* _c-navigation.scss */
/*********************/
.nav {
  margin-bottom: 1.5em;
  margin-top: 1em;
  width: 80%; }
  @media (min-width: 1024px) {
    .nav {
      margin-bottom: 0;
      margin-top: 0;
      width: auto; } }
  .nav .nav__list {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    list-style: none;
    padding-left: 0; }
    @media (min-width: 1024px) {
      .nav .nav__list {
        flex-direction: row; } }
    .nav .nav__list .nav__item {
      border-bottom: 1px solid #E5E5E5;
      margin-left: 1em;
      margin-right: 1em;
      padding-bottom: .75em;
      padding-top: .75em;
      text-align: center;
      width: 100%; }
      @media (min-width: 1024px) {
        .nav .nav__list .nav__item {
          border-bottom: none;
          padding-bottom: 0;
          padding-top: 0;
          width: auto; } }
      .nav .nav__list .nav__item .nav__link {
        color: #4A4A4A;
        font-weight: 700;
        text-decoration: none;
        text-transform: uppercase;
        width: 100%; }
        .nav .nav__list .nav__item .nav__link::after {
          background-color: transparent;
          content: '';
          display: block;
          height: 3px;
          transition: background-color 220ms ease-out;
          width: 100%; }
        @media (min-width: 1024px) {
          .nav .nav__list .nav__item .nav__link {
            color: #FFFFFF; } }
        @media (min-width: 1024px) {
          .nav .nav__list .nav__item .nav__link:hover::after {
            background-color: #FFC244; } }
        .nav .nav__list .nav__item .nav__link.active {
          color: #FFC244; }
          @media (min-width: 1024px) {
            .nav .nav__list .nav__item .nav__link.active {
              color: #FFFFFF; }
              .nav .nav__list .nav__item .nav__link.active::after {
                background-color: #FFC244; } }

.sticky .nav .nav__list .nav__item .nav__link {
  color: #4A4A4A; }

/*************************/
/* _c-outlet-modal.scss */
/***********************/
.outlet-modal .outlet-modal__body {
  padding: 1.25em; }
  .outlet-modal .outlet-modal__body .outlet-modal__title {
    color: #37A49A;
    font-weight: 700;
    padding-left: .5em; }
  .outlet-modal .outlet-modal__body .outlet-modal__form-wrapper {
    margin-top: 1.25em; }
    .outlet-modal .outlet-modal__body .outlet-modal__form-wrapper .outlet-modal__form {
      display: flex;
      flex-wrap: wrap; }

.outlet-modal .outlet-modal__actions {
  text-align: center; }
  .outlet-modal .outlet-modal__actions .outlet-modal__cta {
    background-color: #FFC244;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 6.25em;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1;
    padding: .6em 2.5em;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 220ms ease-out; }
    @media (min-width: 641px) {
      .outlet-modal .outlet-modal__actions .outlet-modal__cta {
        font-size: 1.25rem; } }
    .outlet-modal .outlet-modal__actions .outlet-modal__cta:hover {
      background-color: #ffb111; }

.outlet-modal .outlet-modal__footer {
  display: flex;
  margin-top: 3.25em;
  padding: 1.25em; }
  .outlet-modal .outlet-modal__footer .outlet-modal__footer-info {
    font-size: 0.875rem; }
    .outlet-modal .outlet-modal__footer .outlet-modal__footer-info:not(:last-of-type) {
      margin-right: 1.5em; }

/**********************/
/* _c-paginator.scss */
/********************/
.paginator {
  align-items: center;
  display: flex;
  justify-content: center; }
  .paginator .paginator__arrow {
    color: #37A49A;
    cursor: pointer;
    font-weight: 700;
    height: 1.75em;
    line-height: 1.75em;
    margin-left: .6em;
    margin-right: .6em;
    text-align: center;
    transition: color 220ms ease-out;
    width: 1.75em; }
    .paginator .paginator__arrow:hover {
      color: #2e8981; }
    .paginator .paginator__arrow.disabled {
      color: #E5E5E5;
      cursor: auto;
      pointer-events: none; }
  .paginator .paginator__page {
    background-color: #e8e8e8;
    border-radius: 50%;
    cursor: pointer;
    height: 1.75em;
    line-height: 1.75em;
    font-weight: 700;
    margin-left: .25em;
    margin-right: .25em;
    text-align: center;
    transition: all 220ms ease-out;
    width: 1.75em; }
    .paginator .paginator__page:hover {
      background-color: #dddddd; }
    .paginator .paginator__page.active {
      background-color: #37A49A;
      color: #FFFFFF; }

/******************/
/* _c-phone.scss */
/****************/
.phone-wrapper {
  align-items: center;
  display: flex; }
  .phone-wrapper .phone-icon {
    background-image: url("../images/sprite.svg");
    background-repeat: no-repeat;
    background-position: 39.08045977011494% 83.33333333333333%;
    width: 34px;
    height: 34px; }
  .phone-wrapper .phone-cta {
    cursor: pointer;
    font-family: "Roboto", sans-serif;
    font-size: 0.875rem;
    margin-left: .35em;
    text-decoration: underline; }
    @media (min-width: 461px) {
      .phone-wrapper .phone-cta {
        font-size: 0.9375rem; } }
    @media (min-width: 1280px) {
      .phone-wrapper .phone-cta {
        font-size: 1rem; } }
  .phone-wrapper .phone-number {
    font-family: "Roboto", sans-serif;
    font-size: 0.875rem;
    margin-left: .35em;
    /*opacity: 0;*/
    transition: opacity 220ms ease-out;
    width: 0; }
    @media (min-width: 461px) {
      .phone-wrapper .phone-number {
        font-size: 0.9375rem; } }
    @media (min-width: 1280px) {
      .phone-wrapper .phone-number {
        font-size: 1rem; } }
  .phone-wrapper.open .phone-cta {
    display: none; }
  .phone-wrapper.open .phone-number {
    opacity: 1; }

.process {
  display: flex;
  justify-content: center;
  margin: 1em auto 2em;
  max-width: 26em; }
  .process .process-step {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: center; }
    .process .process-step .process-step__number {
      align-items: center;
      background-color: #E5E5E5;
      border-radius: 50%;
      color: #FFFFFF;
      display: flex;
      font-weight: 700;
      justify-content: center;
      height: 1.5em;
      transition: background-color 220ms ease-out;
      width: 1.5em; }
    .process .process-step .process-step__description {
      color: #E5E5E5;
      font-weight: 700;
      margin-top: .5em;
      text-transform: uppercase;
      transition: color 220ms ease-out; }
    .process .process-step.active .process-step__number {
      background-color: #FFC244; }
    .process .process-step.active .process-step__description {
      color: #FFC244; }
  .process .divider {
    background: #E5E5E5;
    height: 2px;
    margin-left: .75em;
    margin-right: .75em;
    margin-top: .75em;
    width: 100%; }
    .process .divider.divider-2 {
      display: none; }

.proposal-form {
  max-width: 25em;
  margin: 0 auto; }
  .proposal-form .proposal-form__input-wrapper {
    margin-bottom: 1em;
    margin-top: 1em; }
    .proposal-form .proposal-form__input-wrapper .proposal-form__label {
      display: block;
      font-size: 0.875rem; }
    .proposal-form .proposal-form__input-wrapper .proposal-form__input {
      color: #4A4A4A;
      border: 2px solid #E5E5E5;
      border-radius: 4px;
      font-family: "Quicksand", sans-serif;
      line-height: 1.65em;
      margin-top: .25em;
      padding-left: .3em;
      width: 100%; }
  .proposal-form .proposal-form__select-wrapper {
    margin-bottom: 1em;
    margin-top: 1em; }
    @media (min-width: 640px) {
      .proposal-form .proposal-form__select-wrapper {
        align-items: center;
        display: flex; } }
    .proposal-form .proposal-form__select-wrapper .proposal-form__label {
      display: block;
      flex-shrink: 0;
      font-size: 0.875rem;
      margin-right: .5em; }
    .proposal-form .proposal-form__select-wrapper .proposal-form__select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: #FFFFFF;
      background-image: url("../images/icons/icon-chevron-down-yellow.svg");
      background-position: 97%;
      background-repeat: no-repeat;
      background-size: 1em;
      color: #4A4A4A;
      border: 2px solid #E5E5E5;
      border-radius: 4px;
      font-family: "Quicksand", sans-serif;
      height: 2em;
      margin-top: .25em;
      padding-left: .3em;
      width: 100%; }
  .proposal-form .proposal-form__checkbox-wrapper {
    align-items: center;
    display: flex;
    margin-bottom: 1em;
    margin-top: 1em; }
    .proposal-form .proposal-form__checkbox-wrapper .proposal-form__label {
      display: block;
      font-size: 0.875rem;
      margin-left: .5em; }
      .proposal-form .proposal-form__checkbox-wrapper .proposal-form__label a {
        color: #4A4A4A; }
  .proposal-form .proposal-form__submit {
    margin-top: 3em;
    text-align: center; }
    .proposal-form .proposal-form__submit input {
      background-color: #FFC244;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
      border-radius: 6.25em;
      color: #FFFFFF;
      cursor: pointer;
      display: inline-block;
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1;
      padding: .6em 2.5em;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;
      transition: background-color 220ms ease-out; }
      @media (min-width: 641px) {
        .proposal-form .proposal-form__submit input {
          font-size: 1.25rem; } }
      .proposal-form .proposal-form__submit input:hover {
        background-color: #ffb111; }

/*************************/
/* _c-review-modal.scss */
/***********************/
.review-modal .review-modal__rate {
  border-bottom: 1px solid rgba(229, 229, 229, 0.5);
  padding: 1.25em 1.25em 2em; }
  .review-modal .review-modal__rate .rate__title {
    color: #37A49A;
    font-size: 0.875rem;
    font-weight: 700; }
  .review-modal .review-modal__rate .rate__stars-wrapper {
    margin-bottom: .25em;
    margin-top: .5em; }
    .review-modal .review-modal__rate .rate__stars-wrapper .rate__star {
      cursor: pointer; }
  .review-modal .review-modal__rate .rate__decription {
    font-family: "Roboto", sans-serif;
    font-size: 0.875rem;
    font-style: italic; }

.review-modal .review-modal__review {
  padding: 1.25em; }
  .review-modal .review-modal__review .review-modal__review-form .form__element-label {
    color: #37A49A;
    font-weight: 700;
    margin-bottom: .25em; }
  .review-modal .review-modal__review .review-modal__review-form .form__element-input.textarea {
    width: 100%; }
  .review-modal .review-modal__review .review-modal__review-form .form__element-note {
    font-family: "Roboto", sans-serif;
    font-size: 0.875rem;
    font-style: italic;
    text-align: right; }

.review-modal .review-modal__actions {
  text-align: center; }
  .review-modal .review-modal__actions .review-modal__cta {
    background-color: #FFC244;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 6.25em;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1;
    padding: .6em 2.5em;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 220ms ease-out; }
    @media (min-width: 641px) {
      .review-modal .review-modal__actions .review-modal__cta {
        font-size: 1.25rem; } }
    .review-modal .review-modal__actions .review-modal__cta:hover {
      background-color: #ffb111; }

/*******************/
/* _c-review.scss */
/*****************/
.review {
  padding: 1em; }
  .review:not(:last-of-type) {
    border-bottom: 1px solid rgba(155, 155, 155, 0.35); }
  @media (min-width: 640px) {
    .review {
      display: flex; } }
  .review .review__left {
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
    min-width: 6.5em; }
    .review .review__left .review__profile {
      display: none; }
      @media (min-width: 640px) {
        .review .review__left .review__profile {
          display: block;
          text-align: center; } }
      .review .review__left .review__profile .review__photo {
        margin: .25em auto .5em; }
      .review .review__left .review__profile .review__name {
        color: #9B9B9B;
        font-size: 0.875rem;
        font-weight: 700; }
    .review .review__left .review__responsive {
      display: flex;
      justify-content: space-between;
      width: 100%; }
      @media (min-width: 640px) {
        .review .review__left .review__responsive {
          display: none; } }
      .review .review__left .review__responsive .review__responsive-wrapper .review__name {
        color: #9B9B9B;
        font-size: 0.875rem;
        font-weight: 700; }
      .review .review__left .review__responsive .review__responsive-wrapper .review__date {
        font-size: 0.875rem;
        font-weight: 700; }
      .review .review__left .review__responsive .review__rate {
        align-items: center;
        color: #37A49A;
        display: flex;
        font-size: 0.875rem;
        min-width: 3em; }
        .review .review__left .review__responsive .review__rate .review__current-rate {
          font-weight: 700;
          margin-right: .2em; }
  .review .review__right {
    align-items: center;
    display: flex;
    justify-content: center; }
    @media (min-width: 640px) {
      .review .review__right {
        padding: 1em; } }
    @media (min-width: 640px) {
      .review .review__right .review__info {
        padding: .5em; } }
    .review .review__right .review__info .review__date {
      display: none; }
      @media (min-width: 640px) {
        .review .review__right .review__info .review__date {
          display: block;
          font-size: 0.875rem;
          font-weight: 700; } }
    .review .review__right .review__info .review__description {
      font-size: 0.875rem;
      margin-top: .5em; }
      @media (min-width: 640px) {
        .review .review__right .review__info .review__description {
          font-size: 1rem; } }
    .review .review__right .review__rate {
      display: none; }
      @media (min-width: 640px) {
        .review .review__right .review__rate {
          align-items: center;
          color: #37A49A;
          display: flex;
          min-width: 3em; } }
      .review .review__right .review__rate .review__current-rate {
        font-size: 1.25rem;
        font-weight: 700;
        margin-right: .2em; }

/*************************/
/* _c-search-modal.scss */
/***********************/
.search-modal {
  background-color: #37A49A;
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 220ms ease-out;
  z-index: -1; }
  .search-modal.show {
    opacity: 1;
    pointer-events: all;
    z-index: 2; }
  .search-modal .search-modal__wrapper {
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 8px;
    bottom: 2em;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 2em;
    position: absolute;
    right: 2em;
    top: 2em; }
    .search-modal .search-modal__wrapper .search-modal__close {
      background-image: url("../images/sprite.svg");
      background-repeat: no-repeat;
      background-position: 0 83.33333333333333%;
      width: 34px;
      height: 34px;
      cursor: pointer;
      position: absolute;
      right: -1em;
      top: -1em; }
    .search-modal .search-modal__wrapper .search-modal__title {
      color: #37A49A;
      font-size: 1.25rem;
      font-weight: 700;
      margin-bottom: .5em;
      text-align: center; }
      @media (min-width: 461px) {
        .search-modal .search-modal__wrapper .search-modal__title {
          font-size: 1.5rem; } }
      @media (min-width: 641px) {
        .search-modal .search-modal__wrapper .search-modal__title {
          align-items: center;
          display: flex;
          font-size: 1.75rem; } }
      @media (min-width: 1025px) {
        .search-modal .search-modal__wrapper .search-modal__title {
          font-size: 2.375rem; } }
      .search-modal .search-modal__wrapper .search-modal__title::before {
        background-image: url("../images/sprite.svg");
        background-repeat: no-repeat;
        background-position: 0 53.65853658536585%;
        width: 38px;
        height: 49px;
        content: '';
        display: block;
        margin: 0 auto; }
        @media (min-width: 641px) {
          .search-modal .search-modal__wrapper .search-modal__title::before {
            margin-right: 0 .25em 0 0; } }
    .search-modal .search-modal__wrapper .search-modal__search {
      margin-top: 1.5em;
      position: relative;
      width: 85%; }
      @media (min-width: 641px) {
        .search-modal .search-modal__wrapper .search-modal__search {
          width: 75%; } }
      @media (min-width: 1025px) {
        .search-modal .search-modal__wrapper .search-modal__search {
          min-width: 42em;
          width: auto; } }
      .search-modal .search-modal__wrapper .search-modal__search .search-modal__box {
        border-bottom: 2px solid #FFC244;
        color: #E5E5E5;
        font-size: 1.75rem;
        font-weight: 700;
        padding: .012em .25em; }
        @media (min-width: 461px) {
          .search-modal .search-modal__wrapper .search-modal__search .search-modal__box {
            font-size: 2.375rem; } }
        @media (min-width: 641px) {
          .search-modal .search-modal__wrapper .search-modal__search .search-modal__box {
            font-size: 2.875rem; } }
        @media (min-width: 1025px) {
          .search-modal .search-modal__wrapper .search-modal__search .search-modal__box {
            font-size: 3.75rem; } }
        .search-modal .search-modal__wrapper .search-modal__search .search-modal__box.selected {
          color: #4A4A4A; }
      .search-modal .search-modal__wrapper .search-modal__search .search-modal__list {
        background-color: #FFFFFF;
        border-radius: 8px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
        left: 0;
        list-style: none;
        height: 0;
        max-height: 10em;
        overflow-y: auto;
        padding: 0;
        position: absolute;
        right: 0;
        transition: all 220ms ease-out;
        top: 5.5em; }
        .search-modal .search-modal__wrapper .search-modal__search .search-modal__list.open {
          height: auto;
          padding: .75em 1em; }
        .search-modal .search-modal__wrapper .search-modal__search .search-modal__list .search-modal__item {
          cursor: pointer;
          font-family: "Roboto", sans-serif;
          font-size: 1rem;
          font-weight: 700;
          padding-left: .35em; }
          @media (min-width: 461px) {
            .search-modal .search-modal__wrapper .search-modal__search .search-modal__list .search-modal__item {
              font-size: 1.125rem; } }
          @media (min-width: 641px) {
            .search-modal .search-modal__wrapper .search-modal__search .search-modal__list .search-modal__item {
              font-size: 1.25rem; } }
          .search-modal .search-modal__wrapper .search-modal__search .search-modal__list .search-modal__item:hover {
            background-color: rgba(55, 164, 154, 0.125); }
          .search-modal .search-modal__wrapper .search-modal__search .search-modal__list .search-modal__item.item-region {
            font-size: 0.875rem;
            font-weight: 400;
            margin-top: 1.25em;
            padding-left: 0;
            pointer-events: none; }
      .search-modal .search-modal__wrapper .search-modal__search .search-modal__suggestion {
        font-size: 0.875rem;
        margin-top: .35em;
        text-align: right;
        text-transform: uppercase; }

/***************************/
/* _l-component-shop.scss */
/*************************/
.shop {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid; }
  .shop:not(:first-of-type) {
    margin-top: 1em; }
  .shop::before {
    background-color: rgba(155, 155, 155, 0.25);
    content: '';
    display: block;
    height: 1px;
    widows: 100%; }
  .shop .shop__title {
    color: #37A49A;
    font-size: 1rem;
    font-weight: 700;
    padding-bottom: 0; }
    @media (min-width: 460px) {
      .shop .shop__title {
        font-size: 1.125rem; } }
    @media (min-width: 640px) {
      .shop .shop__title {
        font-size: 1.25rem; } }
  .shop .shop__list {
    list-style: none;
    padding-left: 0; }
    .shop .shop__list .shop__item .shop__link {
      color: #4A4A4A;
      font-size: 0.875rem;
      text-decoration: none;
      transition: color 220ms ease-out; }
      @media (min-width: 460px) {
        .shop .shop__list .shop__item .shop__link {
          font-size: 1rem; } }
      .shop .shop__list .shop__item .shop__link:hover {
        color: #9B9B9B; }

/******************/
/* _c-slick.scss */
/****************/
.slick {
  opacity: 0;
  transition: opacity 750ms cubic-bezier(0.55, 0.75, 0, 1); }
  .slick.slick-initialized {
    opacity: 1; }

/*******************/
/* _c-social.scss */
/*****************/
.nav__social .social__menu {
  display: flex;
  list-style: none;
  padding-left: 0; }
  .nav__social .social__menu .social__item {
    margin-left: .5em;
    margin-right: .5em; }
    .nav__social .social__menu .social__item .social__link {
      color: #FFFFFF;
      display: flex;
      overflow: hidden;
      padding-bottom: .2em;
      padding-top: .2em;
      text-indent: -9999px; }
      .nav__social .social__menu .social__item .social__link::after {
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        display: block;
        height: 1.5em;
        transition: all 220ms ease-out;
        width: 1.5em; }
      .nav__social .social__menu .social__item .social__link.facebook::after {
        background-image: url("../images/icons/icon-fb-active.svg"); }
        @media (min-width: 1024px) {
          .nav__social .social__menu .social__item .social__link.facebook::after {
            background-image: url("../images/icons/icon-fb-inactive.svg"); } }
      .nav__social .social__menu .social__item .social__link.instagram::after {
        background-image: url("../images/icons/icon-ig-active.svg"); }
        @media (min-width: 1024px) {
          .nav__social .social__menu .social__item .social__link.instagram::after {
            background-image: url("../images/icons/icon-ig-inactive.svg"); } }
      .nav__social .social__menu .social__item .social__link:hover::after {
        transform: translateY(-0.075em); }

.nav__social.social-footer .social__menu .social__item .social__link.facebook::after {
  background-image: url("../images/icons/icon-fb-small-inactive.svg"); }

.nav__social.social-footer .social__menu .social__item .social__link.instagram::after {
  background-image: url("../images/icons/icon-ig-small-inactive.svg"); }

.sticky .social__menu .social__item .social__link.facebook::after {
  background-image: url("../images/icons/icon-fb-active.svg"); }

.sticky .social__menu .social__item .social__link.instagram::after {
  background-image: url("../images/icons/icon-ig-active.svg"); }

/******************/
/* _c-steps.scss */
/****************/
.steps .step {
  align-items: center;
  display: flex;
  margin-bottom: 2em;
  margin-top: 2em; }
  .steps .step .step__image {
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    margin-right: 1em;
    height: 3em;
    width: 3em; }
    @media (min-width: 768px) {
      .steps .step .step__image {
        height: 4em;
        width: 4em; } }
    .steps .step .step__image img {
      max-height: 2em;
      max-width: 2em; }
      @media (min-width: 768px) {
        .steps .step .step__image img {
          max-height: 2.75em;
          max-width: 2.75em; } }
  .steps .step .step__description {
    color: #FFFFFF;
    font-size: 1.25rem;
    line-height: 1.15; }
    @media (min-width: 768px) {
      .steps .step .step__description {
        font-size: 1.75rem; } }

/*******************/
/* _l-footer.scss */
/*****************/
.footer {
  border-top: 1px solid #E5E5E5; }
  @media (min-width: 769px) {
    .footer {
      display: flex; } }
  .footer .footer-left {
    align-items: center;
    background-color: #37A49A;
    display: flex;
    justify-content: center;
    padding: 2.5em 1em; }
    @media (min-width: 769px) {
      .footer .footer-left {
        width: 50%; } }
    .footer .footer-left .footer-left__link {
      text-decoration: none; }
    .footer .footer-left .footer-left__text {
      margin-left: 1em; }
      .footer .footer-left .footer-left__text .footer-left__title {
        color: #FFFFFF;
        font-size: 1rem;
        font-weight: 700; }
        @media (min-width: 461px) {
          .footer .footer-left .footer-left__text .footer-left__title {
            font-size: 1.25rem; } }
        @media (min-width: 641px) {
          .footer .footer-left .footer-left__text .footer-left__title {
            font-size: 1.75rem; } }
      .footer .footer-left .footer-left__text .footer-left__description {
        color: #FFFFFF;
        font-size: 0.875rem; }
        @media (min-width: 461px) {
          .footer .footer-left .footer-left__text .footer-left__description {
            font-size: 1rem; } }
  .footer .footer-right {
    background-color: #FFFFFF;
    padding: 1.5em; }
    @media (min-width: 769px) {
      .footer .footer-right {
        padding: 1.5em 3em;
        width: 50%; } }
    .footer .footer-right .footer-right__top .footer-right__cta {
      background-color: transparent;
      border: 1px solid #FFC244;
      border-radius: 6.25em;
      color: #4A4A4A;
      cursor: pointer;
      display: inline-block;
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1.5;
      padding: .6em 1.75em;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;
      transition: background-color 220ms ease-out;
      padding-right: 2.5em;
      align-items: center;
      display: inline-flex;
      font-size: 0.75rem;
      padding-bottom: .2em;
      padding-top: .2em; }
      .footer .footer-right .footer-right__top .footer-right__cta:hover {
        background-color: rgba(255, 194, 68, 0.125);
        border-color: #ffb111; }
      .footer .footer-right .footer-right__top .footer-right__cta::before {
        content: '';
        display: block;
        right: 1em;
        top: 50%;
        transform: translateY(-50%);
        position: absolute; }
      @media (min-width: 461px) {
        .footer .footer-right .footer-right__top .footer-right__cta {
          font-size: 0.875rem; } }
      .footer .footer-right .footer-right__top .footer-right__cta::before {
        background-image: url("../images/sprite.svg");
        background-repeat: no-repeat;
        background-position: 93.19371727748691% 90%;
        width: 17px;
        height: 22px;
        content: '';
        display: block; }
      .footer .footer-right .footer-right__top .footer-right__cta::after {
        background-image: url("../images/sprite.svg");
        background-repeat: no-repeat;
        background-position: 19.54022988505747% 83.33333333333333%;
        width: 34px;
        height: 34px;
        content: '';
        display: block;
        order: -1; }
    .footer .footer-right .footer-right__bottom {
      align-items: center;
      display: flex;
      justify-content: space-between; }
      .footer .footer-right .footer-right__bottom .footer-right__description {
        font-size: 0.875rem;
        margin-top: 1.5em; }

/* Safari 10.1+ */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .footer {
      min-height: 9em; } } }

/*******************/
/* _l-header.scss */
/*****************/
.header {
  background-color: rgba(255, 255, 255, 0.95);
  border-bottom: 1px solid #E5E5E5;
  height: 4.8rem;
  left: 0;
  padding: 1em;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 220ms ease-out;
  width: 100%;
  z-index: 2; }
  @media (min-width: 1024px) {
    .header {
      background-color: rgba(255, 255, 255, 0.01);
      border-bottom: none;
      height: auto; } }
  .header .header__wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between; }
    .header .header__wrapper .header__menu {
      align-items: center;
      background-color: #FFFFFF;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      left: 0;
      position: fixed;
      right: 0;
      top: 4.8rem;
      transform: translateX(120%);
      transition: transform 750ms cubic-bezier(0.55, 0.75, 0, 1);
      z-index: -1; }
      @media (min-width: 1024px) {
        .header .header__wrapper .header__menu {
          background-color: rgba(255, 255, 255, 0);
          flex-direction: row;
          position: static;
          transform: translateX(0%);
          z-index: 0; } }
      .header .header__wrapper .header__menu.open-menu {
        transform: translateX(0%); }
    .header .header__wrapper .header__menu-icon {
      background-image: url("../images/icons/icon-menu-burger.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      cursor: pointer;
      display: block;
      height: 2em;
      transition: background-image 220ms ease-out;
      width: 2em; }
      @media (min-width: 1024px) {
        .header .header__wrapper .header__menu-icon {
          display: none; } }
      .header .header__wrapper .header__menu-icon.close-icon {
        background-image: url("../images/icons/icon-close.svg"); }
  .header.sticky {
    background-color: rgba(255, 255, 255, 0.95);
    border-bottom: 1px solid #E5E5E5;
    height: 4.8rem; }

/*****************/
/* _l-main.scss */
/***************/
body .main-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%; }
  body .main-wrapper .main {
    flex-grow: 1; }

.main-wrapper:not(.front-page) {
  padding-top: 4.8rem; }

/***********************************/
/* _l-page-arredatore-detail.scss */
/*********************************/
.arredatori-dettaglio-page .arredatori-dettaglio__wrapper {
  display: flex; }
  .arredatori-dettaglio-page .arredatori-dettaglio__wrapper .arredatori-dettaglio__wrapper-left {
    width: 100%; }
    @media (min-width: 1200px) {
      .arredatori-dettaglio-page .arredatori-dettaglio__wrapper .arredatori-dettaglio__wrapper-left {
        padding-right: 1em;
        width: 70%; } }
  .arredatori-dettaglio-page .arredatori-dettaglio__wrapper .arredatori-dettaglio__wrapper-right {
    width: 0; }
    @media (min-width: 1200px) {
      .arredatori-dettaglio-page .arredatori-dettaglio__wrapper .arredatori-dettaglio__wrapper-right {
        padding-left: .5em;
        width: 30%; } }

/****************************/
/* _l-page-arredatore.scss */
/**************************/
@media (min-width: 1200px) {
  .arredatori-page .arredatori-wrapper {
    display: flex; } }

.arredatori-page .arredatori-wrapper .arredatori-wrapper__left {
  width: 100%; }
  @media (min-width: 1200px) {
    .arredatori-page .arredatori-wrapper .arredatori-wrapper__left {
      width: 65%; } }

.arredatori-page .arredatori-wrapper .arredatori-wrapper__right {
  width: 0; }
  @media (min-width: 1200px) {
    .arredatori-page .arredatori-wrapper .arredatori-wrapper__right {
      width: 35%; } }

.arredatori-page .footer {
  padding-bottom: 7em; }
  @media (min-width: 1200px) {
    .arredatori-page .footer {
      padding-bottom: 0; } }

.landing {
  background-image: url("../images/cover/02.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 0 !important;
  position: relative; }
  .landing::before {
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; }
  .landing .main {
    position: relative;
    z-index: 1; }
    @media (min-width: 960px) {
      .landing .main {
        display: flex;
        justify-content: space-between; } }
    .landing .main .landing__left,
    .landing .main .landing__right {
      flex: 1;
      padding: 2em; }

/************************/
/* _l-page-negozi.scss */
/**********************/
.negozi-page h1 {
  color: #37A49A;
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: .75em; }
  @media (min-width: 640px) {
    .negozi-page h1 {
      font-size: 2rem; } }
  @media (min-width: 768px) {
    .negozi-page h1 {
      font-size: 2.375rem; } }

/****************************/
/* _l-section-aboutus.scss */
/**************************/
.section__aboutus {
  padding: 2em .5em 1.25em; }
  @media (min-width: 641px) {
    .section__aboutus {
      padding: 2em 1em 4em; } }
  .section__aboutus .aboutus__title {
    color: #37A49A;
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: .5em;
    text-align: center; }
    @media (min-width: 461px) {
      .section__aboutus .aboutus__title {
        font-size: 2rem; } }
    @media (min-width: 769px) {
      .section__aboutus .aboutus__title {
        font-size: 2.375rem; } }
    .section__aboutus .aboutus__title::after {
      background-color: #FFC244;
      content: '';
      display: block;
      height: 3px;
      margin: .2em auto;
      width: 1.5em; }
  .section__aboutus .aboutus__gallery {
    padding-left: 2.25em;
    padding-right: 2.25em;
    position: relative; }
    @media (min-width: 769px) {
      .section__aboutus .aboutus__gallery {
        padding-left: 2em;
        padding-right: 2em; } }
    .section__aboutus .aboutus__gallery .opinion {
      max-width: 16.5rem;
      padding: .25em;
      text-align: center; }
      .section__aboutus .aboutus__gallery .opinion .opinion__image {
        margin: 0 auto; }
      .section__aboutus .aboutus__gallery .opinion .opinion__name {
        color: #37A49A;
        font-weight: 700; }
      .section__aboutus .aboutus__gallery .opinion .opinion__subtitle {
        font-size: 0.8125rem;
        font-weight: 700; }
        @media (min-width: 769px) {
          .section__aboutus .aboutus__gallery .opinion .opinion__subtitle {
            font-size: 0.875rem; } }
        .section__aboutus .aboutus__gallery .opinion .opinion__subtitle::after {
          background-color: rgba(155, 155, 155, 0.5);
          content: '';
          display: block;
          height: 1px;
          margin: .5em auto;
          width: 2em; }
      .section__aboutus .aboutus__gallery .opinion .opinion__description {
        line-height: 1.4; }
    .section__aboutus .aboutus__gallery .slick-slide > div {
      text-align: center; }
    .section__aboutus .aboutus__gallery .slick-arrow {
      position: absolute;
      text-indent: -9999px;
      top: 50%;
      transform: translateY(-50%) scale(0.75); }
      @media (min-width: 641px) {
        .section__aboutus .aboutus__gallery .slick-arrow {
          transform: translateY(-50%); } }
      .section__aboutus .aboutus__gallery .slick-arrow::after {
        content: '';
        display: block; }
      .section__aboutus .aboutus__gallery .slick-arrow.slick-prev {
        left: 0; }
        .section__aboutus .aboutus__gallery .slick-arrow.slick-prev::after {
          background-image: url("../images/sprite.svg");
          background-repeat: no-repeat;
          background-position: 53.75% 53.225806451612904%;
          width: 48px;
          height: 48px; }
      .section__aboutus .aboutus__gallery .slick-arrow.slick-next {
        right: 0; }
        .section__aboutus .aboutus__gallery .slick-arrow.slick-next::after {
          background-image: url("../images/sprite.svg");
          background-repeat: no-repeat;
          background-position: 23.75% 53.225806451612904%;
          width: 48px;
          height: 48px; }

/*************************************/
/* _l-section-contact-designer.scss */
/************************************/
.section__contact-designer {
  background-color: #F3F3F3;
  border-radius: 8px;
  margin-bottom: 3em;
  padding: 1em; }
  @media (min-width: 640px) {
    .section__contact-designer {
      padding: 2.5em; } }
  .section__contact-designer .contact-designer__title {
    color: #37A49A;
    font-size: 1.25rem;
    text-align: center; }
    @media (min-width: 640px) {
      .section__contact-designer .contact-designer__title {
        font-size: 1.5rem; } }
    @media (min-width: 768px) {
      .section__contact-designer .contact-designer__title {
        font-size: 1.75rem; } }
  .section__contact-designer .contact-designer__form {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5em; }
  .section__contact-designer .form__cta-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%; }
    .section__contact-designer .form__cta-wrapper .form__cta {
      background-color: #FFC244;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
      border-radius: 6.25em;
      color: #FFFFFF;
      cursor: pointer;
      display: inline-block;
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1;
      padding: .6em 2.5em;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;
      transition: background-color 220ms ease-out; }
      @media (min-width: 641px) {
        .section__contact-designer .form__cta-wrapper .form__cta {
          font-size: 1.25rem; } }
      .section__contact-designer .form__cta-wrapper .form__cta:hover {
        background-color: #ffb111; }

/*********************************/
/* _l-section-detailheader.scss */
/*******************************/
.detail-header {
  padding-bottom: .5em; }
  @media (min-width: 640px) {
    .detail-header {
      padding-bottom: 2em; } }
  .detail-header .detail-header__top {
    max-width: 1350px;
    margin-left: auto;
    margin-right: auto;
    width: 95%; }
    .detail-header .detail-header__top .detail-header__top-wrapper {
      padding-bottom: 1.25em;
      padding-top: 1.25em; }
      @media (min-width: 640px) {
        .detail-header .detail-header__top .detail-header__top-wrapper {
          display: flex;
          justify-content: space-between; } }
      @media (min-width: 1200px) {
        .detail-header .detail-header__top .detail-header__top-wrapper {
          max-width: 65%; } }
      .detail-header .detail-header__top .detail-header__top-wrapper .detail-header__top-left .deatil-header__title {
        color: #37A49A;
        font-size: 1.5rem;
        line-height: 1; }
        @media (min-width: 460px) {
          .detail-header .detail-header__top .detail-header__top-wrapper .detail-header__top-left .deatil-header__title {
            font-size: 1.75rem; } }
        @media (min-width: 640px) {
          .detail-header .detail-header__top .detail-header__top-wrapper .detail-header__top-left .deatil-header__title {
            font-size: 2rem; } }
        @media (min-width: 768px) {
          .detail-header .detail-header__top .detail-header__top-wrapper .detail-header__top-left .deatil-header__title {
            font-size: 2.375rem; } }
      .detail-header .detail-header__top .detail-header__top-wrapper .detail-header__top-left .deatil-header__address {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.1; }
        @media (min-width: 460px) {
          .detail-header .detail-header__top .detail-header__top-wrapper .detail-header__top-left .deatil-header__address {
            font-size: 1rem; } }
        @media (min-width: 640px) {
          .detail-header .detail-header__top .detail-header__top-wrapper .detail-header__top-left .deatil-header__address {
            font-size: 1.125rem; } }
        @media (min-width: 768px) {
          .detail-header .detail-header__top .detail-header__top-wrapper .detail-header__top-left .deatil-header__address {
            font-size: 1.25rem; } }
      .detail-header .detail-header__top .detail-header__top-wrapper .detail-header__top-right .detail-header__stars {
        justify-content: flex-start; }
        @media (min-width: 640px) {
          .detail-header .detail-header__top .detail-header__top-wrapper .detail-header__top-right .detail-header__stars {
            justify-content: flex-end; } }
  .detail-header .detail-header__menu {
    display: none; }
    @media (min-width: 640px) {
      .detail-header .detail-header__menu {
        border-bottom: 1px solid rgba(155, 155, 155, 0.125);
        border-top: 1px solid rgba(155, 155, 155, 0.125);
        display: block; } }
    .detail-header .detail-header__menu .detail-menu__wrapper {
      max-width: 1350px;
      margin-left: auto;
      margin-right: auto;
      width: 95%;
      padding-bottom: .625em;
      padding-top: .625em; }
      .detail-header .detail-header__menu .detail-menu__wrapper .detail-menu__list {
        display: flex;
        list-style: none;
        padding-left: 0; }
        @media (min-width: 1200px) {
          .detail-header .detail-header__menu .detail-menu__wrapper .detail-menu__list {
            max-width: 70%; } }
        .detail-header .detail-header__menu .detail-menu__wrapper .detail-menu__list .detail-menu__item {
          margin-left: .45em;
          margin-right: .45em; }
          @media (min-width: 1024px) {
            .detail-header .detail-header__menu .detail-menu__wrapper .detail-menu__list .detail-menu__item {
              margin-left: .75em;
              margin-right: .75em; } }
          .detail-header .detail-header__menu .detail-menu__wrapper .detail-menu__list .detail-menu__item:first-of-type {
            margin-left: 0; }
          .detail-header .detail-header__menu .detail-menu__wrapper .detail-menu__list .detail-menu__item .detail-menu__link {
            color: #9B9B9B;
            font-size: 0.75rem;
            font-weight: 700;
            text-decoration: none;
            text-transform: uppercase;
            transition: color 220ms ease-out; }
            @media (min-width: 768px) {
              .detail-header .detail-header__menu .detail-menu__wrapper .detail-menu__list .detail-menu__item .detail-menu__link {
                font-size: 0.875rem; } }
            .detail-header .detail-header__menu .detail-menu__wrapper .detail-menu__list .detail-menu__item .detail-menu__link:hover {
              color: #4A4A4A; }

/************************************/
/* _l-section-designerresults.scss */
/**********************************/
.section__designers-results .designer-results__summary {
  margin-left: 1em;
  margin-top: 1.25em; }
  .section__designers-results .designer-results__summary .designer-results__title {
    font-weight: 700; }
  .section__designers-results .designer-results__summary .designer-results__nresults {
    color: #37A49A;
    margin-top: .5em; }
  .section__designers-results .designer-results__summary .designer-results__breadcrumb {
    font-size: 0.75rem; }

.section__designers-results .designer-results__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  @media (min-width: 1200px) {
    .section__designers-results .designer-results__list {
      justify-content: flex-start; } }

.section__designers-results .designer-results__paginator {
  margin: 1.5em auto 3.5em; }

/*******************************/
/* _l-section-detailhero.scss */
/*****************************/
.detail-hero {
  position: relative;
  overflow: hidden; }
  .detail-hero .detail-hero__gallery {
    height: 32vh;
    position: relative;
    z-index: 1; }
    @media (min-width: 640px) {
      .detail-hero .detail-hero__gallery {
        height: 50vh; } }
    .detail-hero .detail-hero__gallery .detail-hero__gallery-content {
      height: 100%; }
      .detail-hero .detail-hero__gallery .detail-hero__gallery-content .slick-list {
        height: 100%; }
        .detail-hero .detail-hero__gallery .detail-hero__gallery-content .slick-list .slick-track {
          height: 100%; }
          .detail-hero .detail-hero__gallery .detail-hero__gallery-content .slick-list .slick-track .slick-slide {
            height: 100%; }
            .detail-hero .detail-hero__gallery .detail-hero__gallery-content .slick-list .slick-track .slick-slide div {
              height: 100%;
              margin-left: .125em;
              margin-right: .125em; }
              .detail-hero .detail-hero__gallery .detail-hero__gallery-content .slick-list .slick-track .slick-slide div .detail-hero__gallery-item {
                height: 100%;
                width: auto !important; }
      .detail-hero .detail-hero__gallery .detail-hero__gallery-content .slick-arrow {
        position: absolute;
        text-indent: -9999px;
        top: 50%;
        transform: translateY(-50%) scale(0.75);
        z-index: 2; }
        @media (min-width: 641px) {
          .detail-hero .detail-hero__gallery .detail-hero__gallery-content .slick-arrow {
            transform: translateY(-50%); } }
        .detail-hero .detail-hero__gallery .detail-hero__gallery-content .slick-arrow::after {
          content: '';
          display: block; }
        .detail-hero .detail-hero__gallery .detail-hero__gallery-content .slick-arrow.slick-prev {
          left: 0; }
          .detail-hero .detail-hero__gallery .detail-hero__gallery-content .slick-arrow.slick-prev::after {
            background-image: url("../images/sprite.svg");
            background-repeat: no-repeat;
            background-position: 34.72222222222222% 0;
            width: 64px;
            height: 64px; }
        .detail-hero .detail-hero__gallery .detail-hero__gallery-content .slick-arrow.slick-next {
          right: 0; }
          .detail-hero .detail-hero__gallery .detail-hero__gallery-content .slick-arrow.slick-next::after {
            background-image: url("../images/sprite.svg");
            background-repeat: no-repeat;
            background-position: 79.16666666666667% 0;
            width: 64px;
            height: 64px; }
  .detail-hero .detail-hero__discount {
    align-items: stretch;
    bottom: 0;
    display: inline-flex;
    height: 3em;
    left: 0;
    position: absolute;
    z-index: 2; }
    @media (min-width: 640px) {
      .detail-hero .detail-hero__discount {
        height: 3.35em; } }
    @media (min-width: 768px) {
      .detail-hero .detail-hero__discount {
        height: 4em; } }
    .detail-hero .detail-hero__discount::after {
      background-image: url("../images/elements/discount-flag.svg");
      background-position: left;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      display: block;
      height: 102%;
      margin-left: -1px;
      width: 1.5em; }
    .detail-hero .detail-hero__discount .detail-hero__discount-wrapper {
      background-color: #FFC244;
      padding: .25em .5em;
      text-align: center; }
      .detail-hero .detail-hero__discount .detail-hero__discount-wrapper .detail-hero__discount-description {
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 1; }
        @media (min-width: 640px) {
          .detail-hero .detail-hero__discount .detail-hero__discount-wrapper .detail-hero__discount-description {
            font-size: 0.875rem; } }
      .detail-hero .detail-hero__discount .detail-hero__discount-wrapper .detail-hero__discount-value {
        color: #FFFFFF;
        font-size: 1.75rem;
        font-weight: 700;
        line-height: 1; }
        @media (min-width: 640px) {
          .detail-hero .detail-hero__discount .detail-hero__discount-wrapper .detail-hero__discount-value {
            font-size: 2rem; } }
        @media (min-width: 768px) {
          .detail-hero .detail-hero__discount .detail-hero__discount-wrapper .detail-hero__discount-value {
            font-size: 2.375rem; } }

/*************************/
/* _l-section-help.scss */
/***********************/
.section__help {
  display: none; }
  @media (min-width: 1200px) {
    .section__help {
      background-color: #F2F9F8;
      background-image: url("../images/elements/help.svg");
      background-position: 1em 1em;
      background-repeat: no-repeat;
      border: 2px solid #FFFFFF;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.17);
      border-radius: 8px;
      display: block;
      margin-top: 6.2em;
      overflow: hidden; } }
  .section__help .help-box__body {
    padding: 3em 1em 1em;
    position: relative; }
    .section__help .help-box__body .help-box__title {
      color: #37A49A;
      font-size: 1.75rem;
      text-align: center; }
    .section__help .help-box__body .help-box__description {
      line-height: 1.5;
      margin-top: .25em;
      text-align: center; }
    .section__help .help-box__body .help-box__action {
      border-bottom: 3px solid #FFC244;
      border-top: 3px solid #FFC244;
      color: #37A49A;
      font-size: 1.25rem;
      margin-bottom: 1em;
      margin-top: 1em;
      padding-bottom: .5em;
      padding-top: .5em;
      text-align: center; }
    .section__help .help-box__body .help-box__item {
      color: #37A49A;
      font-size: 0.875rem;
      margin-bottom: .5em;
      margin-top: .5em; }
  .section__help .help-box__footer {
    background-color: #37A49A;
    padding: 1.25em;
    text-align: center; }
    .section__help .help-box__footer .help-box__cta {
      background-color: #FFC244;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
      border-radius: 6.25em;
      color: #FFFFFF;
      cursor: pointer;
      display: inline-block;
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1;
      padding: .6em 2.5em;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;
      transition: background-color 220ms ease-out; }
      @media (min-width: 641px) {
        .section__help .help-box__footer .help-box__cta {
          font-size: 1.5rem; } }
      .section__help .help-box__footer .help-box__cta:hover {
        background-color: #ffb111; }

.section__help-mobile {
  background-color: #37A49A;
  bottom: 0;
  left: 0;
  padding: 1em .5em;
  position: fixed;
  right: 0;
  z-index: 1; }
  @media (min-width: 1200px) {
    .section__help-mobile {
      display: none; } }
  .section__help-mobile .help-box__body {
    max-width: 40em;
    margin-left: auto;
    margin-right: auto;
    width: 95%;
    align-items: center;
    display: flex;
    justify-content: center; }
    .section__help-mobile .help-box__body .help-box__left {
      max-width: 14em; }
      @media (min-width: 640px) {
        .section__help-mobile .help-box__body .help-box__left {
          max-width: 20em; } }
      .section__help-mobile .help-box__body .help-box__left .help-box__title {
        color: #FFC244;
        font-size: 1.125rem;
        font-weight: 700; }
        @media (min-width: 640px) {
          .section__help-mobile .help-box__body .help-box__left .help-box__title {
            font-size: 1.75rem; } }
      .section__help-mobile .help-box__body .help-box__left .help-box__description {
        color: #FFFFFF;
        font-size: 0.875rem;
        font-weight: 700; }
        @media (min-width: 640px) {
          .section__help-mobile .help-box__body .help-box__left .help-box__description {
            font-size: 1.25rem; } }
    .section__help-mobile .help-box__body .help-box__right .help-box__cta {
      background-color: #FFC244;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
      border-radius: 6.25em;
      color: #FFFFFF;
      cursor: pointer;
      display: inline-block;
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1;
      padding: .6em 2.5em;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;
      transition: background-color 220ms ease-out;
      font-size: 1rem !important;
      padding: .35em 1.75em; }
      @media (min-width: 641px) {
        .section__help-mobile .help-box__body .help-box__right .help-box__cta {
          font-size: 1.5rem; } }
      .section__help-mobile .help-box__body .help-box__right .help-box__cta:hover {
        background-color: #ffb111; }
      @media (min-width: 640px) {
        .section__help-mobile .help-box__body .help-box__right .help-box__cta {
          font-size: 1.25rem !important; } }

/*************************/
/* _l-section-hero.scss */
/***********************/
.section__hero {
  height: 90vh;
  position: relative;
  width: 100%; }
  @media (min-width: 641px) {
    .section__hero {
      align-items: center;
      background-color: #77FFF2;
      display: flex;
      justify-content: center;
      height: 100vh; } }
  @media (min-width: 641px) {
    .section__hero::after {
      background-color: rgba(0, 0, 0, 0.4);
      bottom: 0;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0; } }
  .section__hero .hero__gallery {
    height: 50%;
    width: 100%; }
    @media (min-width: 641px) {
      .section__hero .hero__gallery {
        bottom: 0;
        left: 0;
        height: auto;
        position: absolute;
        right: 0;
        top: 0; } }
    .section__hero .hero__gallery div {
      height: 100% !important; }
    .section__hero .hero__gallery .hero__gallery-content .hero__gallery-item {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; }
  .section__hero .hero__content {
    background-color: #FFFFFF;
    padding-top: 2em;
    position: relative;
    text-align: center;
    z-index: 1; }
    @media (min-width: 641px) {
      .section__hero .hero__content {
        background-color: transparent;
        padding-top: 0; } }
    .section__hero .hero__content .hero__content-label {
      color: #4A4A4A;
      font-size: 1.25rem;
      font-weight: 700;
      will-change: transform; }
      @media (min-width: 641px) {
        .section__hero .hero__content .hero__content-label {
          color: #FFFFFF;
          font-size: 2.875rem; } }
    .section__hero .hero__content .hero__content-box {
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 15px;
      border: 1px solid #FFFFFF;
      color: #37A49A;
      cursor: pointer;
      display: inline-block;
      font-size: 2.375rem;
      font-weight: 700;
      margin-bottom: .175em;
      margin-top: .175em;
      padding: 0 1.25em;
      position: relative;
      will-change: transform; }
      @media (min-width: 641px) {
        .section__hero .hero__content .hero__content-box {
          display: block;
          font-size: 2.875rem;
          margin-bottom: .3em;
          margin-top: .3em;
          padding: .25em; } }
      .section__hero .hero__content .hero__content-box::after {
        background-image: url("../images/sprite.svg");
        background-repeat: no-repeat;
        background-position: 0 100%;
        width: 30px;
        height: 23px;
        content: '';
        display: block;
        position: absolute;
        right: .25em;
        top: 50%;
        transform: translateY(-50%); }
    .section__hero .hero__content .hero__content-description {
      font-size: 1.125rem;
      letter-spacing: -0.5px;
      will-change: transform; }
      @media (min-width: 641px) {
        .section__hero .hero__content .hero__content-description {
          color: #FFFFFF;
          font-size: 2rem;
          text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); } }
    .section__hero .hero__content .hero__content-cta {
      background-color: #FFC244;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
      border-radius: 6.25em;
      color: #FFFFFF;
      cursor: pointer;
      display: inline-block;
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1;
      padding: .6em 2.5em;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;
      transition: background-color 220ms ease-out;
      padding-left: 3em;
      margin-top: .6em; }
      @media (min-width: 641px) {
        .section__hero .hero__content .hero__content-cta {
          font-size: 1.5rem; } }
      .section__hero .hero__content .hero__content-cta:hover {
        background-color: #ffb111; }
      .section__hero .hero__content .hero__content-cta::before {
        content: '';
        display: block;
        left: 1em;
        top: 50%;
        transform: translateY(-50%);
        position: absolute; }
      .section__hero .hero__content .hero__content-cta::before {
        background-image: url("../images/sprite.svg");
        background-repeat: no-repeat;
        background-position: 58.285714285714285% 82.14285714285714%;
        width: 33px;
        height: 32px; }
  .section__hero .hero__scroll {
    background-color: #37A49A;
    border-radius: 50%;
    bottom: -1.5em;
    cursor: pointer;
    height: 3em;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 3em;
    will-change: transform;
    z-index: 1; }
    .section__hero .hero__scroll::before {
      background-image: url("../images/sprite.svg");
      background-repeat: no-repeat;
      background-position: 75.84269662921348% 77.18120805369128%;
      width: 30px;
      height: 23px;
      content: '';
      display: block;
      margin: .2em auto; }

/**************************/
/* _l-section-hours.scss */
/************************/
.section__hours {
  background-color: #FFFFFF;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.17);
  border-radius: 8px;
  margin-top: -10em;
  padding: 1.5em; }
  .section__hours .hours-box__logo-wrapper .hours-box__logo {
    margin: 0 auto 1em; }
  .section__hours .hours-box__body .hours-box__title {
    font-weight: 700; }
  .section__hours .hours-box__body .hours-box__info {
    margin-top: .2em; }
    .section__hours .hours-box__body .hours-box__info .hours-box__info-row {
      display: flex;
      font-size: 0.875rem;
      justify-content: space-between;
      padding: .12em; }
      .section__hours .hours-box__body .hours-box__info .hours-box__info-row.current {
        background-color: rgba(55, 164, 154, 0.15);
        font-weight: 700; }
  .section__hours .hours-box__footer {
    border-top: 1px solid rgba(229, 229, 229, 0.5);
    margin-top: 1.5em;
    padding-top: 1em; }
    .section__hours .hours-box__footer .hours-box__website {
      align-items: center;
      color: #4A4A4A;
      display: flex;
      font-weight: 700;
      text-decoration: none; }
      .section__hours .hours-box__footer .hours-box__website::before {
        background-image: url("../images/sprite.svg");
        background-repeat: no-repeat;
        background-position: 77.90697674418605% 48.175182481751825%;
        width: 36px;
        height: 35px;
        content: '';
        display: block;
        margin-right: .15em;
        margin-top: .1em; }
    .section__hours .hours-box__footer .hours-box__phone-cta {
      font-weight: 700;
      text-decoration: none; }
  .section__hours.pinnable {
    display: none; }
    @media (min-width: 1200px) {
      .section__hours.pinnable {
        display: block; } }
  .section__hours.responsive {
    display: block;
    margin-top: 2em; }
    @media (min-width: 1200px) {
      .section__hours.responsive {
        display: none; } }

/*************************/
/* _l-section-info.scss */
/***********************/
.section__info .info__title {
  align-items: center;
  color: #37A49A;
  display: flex;
  font-size: 1.125rem;
  font-weight: 700;
  justify-content: space-between;
  margin-bottom: 1em;
  margin-top: 3em; }
  @media (min-width: 640px) {
    .section__info .info__title {
      font-size: 1.25rem; } }
  .section__info .info__title::before, .section__info .info__title::after {
    background-color: #F3F3F3;
    content: '';
    display: block;
    flex-grow: 1;
    height: 1px;
    width: 5%; }
    @media (min-width: 640px) {
      .section__info .info__title::before, .section__info .info__title::after {
        width: 30%; } }
  .section__info .info__title::before {
    margin-right: 1em; }
  .section__info .info__title::after {
    margin-left: 1em; }

.section__info .info__description {
  font-size: 0.875rem; }
  @media (min-width: 640px) {
    .section__info .info__description {
      font-size: 1rem; } }

.section__info .info__rooms {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .section__info .info__rooms .info__room-item {
    margin-bottom: .75em;
    margin-left: .5em;
    margin-right: .5em; }
    @media (min-width: 640px) {
      .section__info .info__rooms .info__room-item {
        margin-left: 1em;
        margin-right: 1em; } }
    .section__info .info__rooms .info__room-item .info__room-image {
      margin: 0 auto;
      max-width: 4.5em; }
    .section__info .info__rooms .info__room-item .info__room-text {
      font-size: 0.875rem;
      font-weight: 700;
      margin-top: .2em;
      text-align: center;
      text-transform: uppercase; }

/*********************************/
/* _l-section-landing-left.scss */
/*******************************/
.section-landing-left {
  display: flex;
  flex-direction: column; }
  .section-landing-left .main__logo {
    flex-shrink: 0; }
  .section-landing-left .landing__info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    margin: 0 auto;
    max-width: 38em; }
    .section-landing-left .landing__info .landing__title {
      color: #FFFFFF;
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 0em;
      margin-top: 2em; }
      @media (min-width: 640px) {
        .section-landing-left .landing__info .landing__title {
          font-size: 2rem;
          margin-bottom: 1em; } }
      @media (min-width: 960px) {
        .section-landing-left .landing__info .landing__title {
          font-size: 2.375rem;
          margin-bottom: 2em;
          margin-top: 0; } }

/**********************************/
/* _l-section-landing-right.scss */
/********************************/
.section-landing-right {
  padding: 1em 3em !important; }
  @media (min-width: 640px) {
    .section-landing-right {
      padding: 3em !important; } }

/************************/
/* _l-section-map.scss */
/**********************/
/********************************/
/* _l-section-outlet-best.scss */
/******************************/
.section__outlet-best {
  background-color: #37A49A;
  padding: 3em 1em;
  text-align: center; }
  .section__outlet-best .outlet-best__title {
    color: #FFFFFF;
    font-size: 1.25rem;
    font-weight: 400; }
    @media (min-width: 461px) {
      .section__outlet-best .outlet-best__title {
        font-size: 1.5rem; } }
    @media (min-width: 769px) {
      .section__outlet-best .outlet-best__title {
        font-size: 2.375rem; } }
  .section__outlet-best .outlet-best__image {
    align-content: center;
    display: flex;
    justify-content: center; }
    .section__outlet-best .outlet-best__image > div {
      margin: 1.25em .35em;
      max-width: 10.6em;
      width: 30%;
      will-change: transform; }
  .section__outlet-best .outlet-best__cta {
    background-color: #FFC244;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 6.25em;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1;
    padding: .6em 2.5em;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 220ms ease-out;
    padding-left: 3em;
    margin-top: .6em;
    will-change: transform; }
    @media (min-width: 641px) {
      .section__outlet-best .outlet-best__cta {
        font-size: 1.5rem; } }
    .section__outlet-best .outlet-best__cta:hover {
      background-color: #ffb111; }
    .section__outlet-best .outlet-best__cta::before {
      content: '';
      display: block;
      left: 1em;
      top: 50%;
      transform: translateY(-50%);
      position: absolute; }
    .section__outlet-best .outlet-best__cta::before {
      background-image: url("../images/sprite.svg");
      background-repeat: no-repeat;
      background-position: 97.8021978021978% 57.142857142857146%;
      width: 26px;
      height: 25px; }
  .section__outlet-best .outlet-best__description {
    color: #FFFFFF;
    font-size: 1rem;
    font-weight: 700;
    margin: .75em auto;
    max-width: 15em;
    will-change: transform; }
    @media (min-width: 461px) {
      .section__outlet-best .outlet-best__description {
        font-size: 1.25rem; } }
    @media (min-width: 769px) {
      .section__outlet-best .outlet-best__description {
        font-size: 1.75rem; } }
    .section__outlet-best .outlet-best__description span {
      color: #FFC244;
      font-size: 1.125rem;
      text-transform: uppercase; }
      @media (min-width: 461px) {
        .section__outlet-best .outlet-best__description span {
          font-size: 1.5rem; } }
      @media (min-width: 769px) {
        .section__outlet-best .outlet-best__description span {
          font-size: 2rem; } }

/************************************/
/* _l-section-outlet-designer.scss */
/**********************************/
.section__outlet-designer {
  margin-bottom: 3em; }
  .section__outlet-designer .outlet-designer__title {
    align-items: center;
    color: #37A49A;
    display: flex;
    font-size: 1.125rem;
    font-weight: 700;
    justify-content: space-between;
    margin-bottom: 1em;
    margin-top: 3em; }
    @media (min-width: 640px) {
      .section__outlet-designer .outlet-designer__title {
        font-size: 1.25rem; } }
    .section__outlet-designer .outlet-designer__title::before, .section__outlet-designer .outlet-designer__title::after {
      background-color: #F3F3F3;
      content: '';
      display: block;
      flex-grow: 1;
      height: 1px;
      width: 5%; }
      @media (min-width: 640px) {
        .section__outlet-designer .outlet-designer__title::before, .section__outlet-designer .outlet-designer__title::after {
          width: 30%; } }
    .section__outlet-designer .outlet-designer__title::before {
      margin-right: 1em; }
    .section__outlet-designer .outlet-designer__title::after {
      margin-left: 1em; }
  .section__outlet-designer .outlet-designer__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .section__outlet-designer .outlet-designer__bottom {
    margin-top: 1.5em;
    text-align: center; }
    .section__outlet-designer .outlet-designer__bottom .outlet-designer__cta {
      background-color: transparent;
      border: 1px solid #FFC244;
      border-radius: 6.25em;
      color: #4A4A4A;
      cursor: pointer;
      display: inline-block;
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1.5;
      padding: .6em 1.75em;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;
      transition: background-color 220ms ease-out;
      padding-right: 2.5em; }
      .section__outlet-designer .outlet-designer__bottom .outlet-designer__cta:hover {
        background-color: rgba(255, 194, 68, 0.125);
        border-color: #ffb111; }
      .section__outlet-designer .outlet-designer__bottom .outlet-designer__cta::before {
        content: '';
        display: block;
        right: 1em;
        top: 50%;
        transform: translateY(-50%);
        position: absolute; }
      .section__outlet-designer .outlet-designer__bottom .outlet-designer__cta::before {
        background-image: url("../images/sprite.svg");
        background-repeat: no-repeat;
        background-position: 93.19371727748691% 90%;
        width: 17px;
        height: 22px;
        content: '';
        display: block; }

/************************************/
/* _l-section-outlet-results.scss */
/**********************************/
.section__outlet-results .outlet-results__summary {
  margin-left: 1em;
  margin-top: 1.25em; }
  .section__outlet-results .outlet-results__summary .outlet-results__title {
    font-weight: 700; }
  .section__outlet-results .outlet-results__summary .outlet-results__nresults {
    color: #37A49A;
    margin-top: .5em; }
  .section__outlet-results .outlet-results__summary .outlet-results__breadcrumb {
    font-size: 0.75rem; }

.section__outlet-results .outlet-results__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2em; }
  @media (min-width: 1200px) {
    .section__outlet-results .outlet-results__list {
      justify-content: flex-start; } }

.section__outlet-results .outlet-results__paginator {
  margin: 1.5em auto 3.5em; }

/*****************************/
/* _l-section-projects.scss */
/***************************/
.section__projects .projects__title {
  align-items: center;
  color: #37A49A;
  display: flex;
  font-size: 1.125rem;
  font-weight: 700;
  justify-content: space-between;
  margin-bottom: 1em;
  margin-top: 3em; }
  @media (min-width: 640px) {
    .section__projects .projects__title {
      font-size: 1.25rem; } }
  .section__projects .projects__title::before, .section__projects .projects__title::after {
    background-color: #F3F3F3;
    content: '';
    display: block;
    flex-grow: 1;
    height: 1px;
    width: 5%; }
    @media (min-width: 640px) {
      .section__projects .projects__title::before, .section__projects .projects__title::after {
        width: 30%; } }
  .section__projects .projects__title::before {
    margin-right: 1em; }
  .section__projects .projects__title::after {
    margin-left: 1em; }

.section__projects .projects__gallery-content-for {
  margin-bottom: .5em; }
  .section__projects .projects__gallery-content-for .slick-list {
    background-color: #F3F3F3; }
    .section__projects .projects__gallery-content-for .slick-list .slick-track {
      align-items: center;
      display: flex;
      height: 100%; }
      .section__projects .projects__gallery-content-for .slick-list .slick-track .slick-slide {
        height: 100%; }
        .section__projects .projects__gallery-content-for .slick-list .slick-track .slick-slide div {
          height: 100%;
          text-align: center; }
          .section__projects .projects__gallery-content-for .slick-list .slick-track .slick-slide div .detail-hero__gallery-item {
            max-height: 100% !important;
            width: auto !important; }
  .section__projects .projects__gallery-content-for .slick-dots {
    align-items: center;
    display: flex;
    justify-content: center;
    list-style: none;
    margin-top: .5em;
    padding-left: 0; }
    .section__projects .projects__gallery-content-for .slick-dots li {
      margin-left: .2em;
      margin-right: .2em; }
      .section__projects .projects__gallery-content-for .slick-dots li button {
        border: 1px solid #E5E5E5;
        border-radius: 50%;
        height: .5em;
        text-indent: -9999px;
        transition: all 220ms ease-out;
        width: .5em; }
      .section__projects .projects__gallery-content-for .slick-dots li.slick-active button {
        background-color: #E5E5E5; }

.section__projects .projects__gallery-content-nav {
  display: none; }
  @media (min-width: 640px) {
    .section__projects .projects__gallery-content-nav {
      display: block; } }
  .section__projects .projects__gallery-content-nav .slick-list .slick-track .slick-slide.slick-current div {
    background-color: #37A49A; }
  .section__projects .projects__gallery-content-nav .slick-list .slick-track .slick-slide div {
    background-color: #FFFFFF;
    transition: background-color 220ms ease-out;
    height: 5em !important;
    padding: .25em;
    text-align: center;
    width: auto !important; }
    .section__projects .projects__gallery-content-nav .slick-list .slick-track .slick-slide div .detail-hero__gallery-item {
      height: 100% !important;
      width: 100% !important; }

/****************************/
/* _l-section-reviews.scss */
/**************************/
.section__reviews {
  margin-bottom: 3em; }
  .section__reviews .reviews__title {
    align-items: center;
    color: #37A49A;
    display: flex;
    font-size: 1.125rem;
    font-weight: 700;
    justify-content: space-between;
    margin-bottom: 1em;
    margin-top: 3em; }
    @media (min-width: 640px) {
      .section__reviews .reviews__title {
        font-size: 1.25rem; } }
    .section__reviews .reviews__title::before, .section__reviews .reviews__title::after {
      background-color: #F3F3F3;
      content: '';
      display: block;
      flex-grow: 1;
      height: 1px;
      width: 5%; }
      @media (min-width: 640px) {
        .section__reviews .reviews__title::before, .section__reviews .reviews__title::after {
          width: 30%; } }
    .section__reviews .reviews__title::before {
      margin-right: 1em; }
    .section__reviews .reviews__title::after {
      margin-left: 1em; }
  .section__reviews .reviews__wrapper {
    border: 1px solid rgba(155, 155, 155, 0.35);
    border-radius: 8px; }
    .section__reviews .reviews__wrapper .reviews__header {
      border-bottom: 1px solid rgba(155, 155, 155, 0.35);
      padding: 1em; }
      @media (min-width: 640px) {
        .section__reviews .reviews__wrapper .reviews__header {
          align-items: center;
          display: flex;
          justify-content: space-between; } }
      .section__reviews .reviews__wrapper .reviews__header .reviews__add {
        margin-top: 1em; }
        @media (min-width: 640px) {
          .section__reviews .reviews__wrapper .reviews__header .reviews__add {
            margin-top: 0; } }
        .section__reviews .reviews__wrapper .reviews__header .reviews__add .reviews__add-cta {
          background-color: transparent;
          border: 1px solid #FFC244;
          border-radius: 6.25em;
          color: #4A4A4A;
          cursor: pointer;
          display: inline-block;
          font-weight: 700;
          font-size: 0.875rem;
          line-height: 1.5;
          padding: .6em 1.75em;
          position: relative;
          text-decoration: none;
          text-transform: uppercase;
          transition: background-color 220ms ease-out;
          padding-right: 2.5em;
          text-transform: none; }
          .section__reviews .reviews__wrapper .reviews__header .reviews__add .reviews__add-cta:hover {
            background-color: rgba(255, 194, 68, 0.125);
            border-color: #ffb111; }
          .section__reviews .reviews__wrapper .reviews__header .reviews__add .reviews__add-cta::before {
            content: '';
            display: block;
            right: 1em;
            top: 50%;
            transform: translateY(-50%);
            position: absolute; }
          .section__reviews .reviews__wrapper .reviews__header .reviews__add .reviews__add-cta::before {
            background-image: url("../images/sprite.svg");
            background-repeat: no-repeat;
            background-position: 93.19371727748691% 90%;
            width: 17px;
            height: 22px;
            content: '';
            display: block; }

/**************************/
/* _l-section-shops.scss */
/************************/
.section__shops {
  column-count: 2;
  column-gap: 2em;
  margin-bottom: 1.5em;
  margin-top: 1em; }
  @media (min-width: 640px) {
    .section__shops {
      column-count: 3; } }
  @media (min-width: 768px) {
    .section__shops {
      column-count: 4; } }
  @media (min-width: 1024px) {
    .section__shops {
      column-count: 6; } }

/**********************************/
/* _l-section-top-designers.scss */
/********************************/
.section__top_designers {
  padding: 4em 1em; }
  .section__top_designers .top-designers__title {
    color: #37A49A;
    font-size: 1.75rem;
    font-weight: 400;
    margin-bottom: .5em;
    text-align: center; }
    @media (min-width: 461px) {
      .section__top_designers .top-designers__title {
        font-size: 2rem; } }
    @media (min-width: 769px) {
      .section__top_designers .top-designers__title {
        font-size: 2.375rem; } }
  .section__top_designers .top-designers__container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
  .section__top_designers .top-designers__cta-wrapper {
    margin-top: 1.5em;
    text-align: center; }
    .section__top_designers .top-designers__cta-wrapper .top-designers__cta {
      background-color: transparent;
      border: 1px solid #FFC244;
      border-radius: 6.25em;
      color: #4A4A4A;
      cursor: pointer;
      display: inline-block;
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1.5;
      padding: .6em 1.75em;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;
      transition: background-color 220ms ease-out;
      padding-right: 2.5em; }
      .section__top_designers .top-designers__cta-wrapper .top-designers__cta:hover {
        background-color: rgba(255, 194, 68, 0.125);
        border-color: #ffb111; }
      .section__top_designers .top-designers__cta-wrapper .top-designers__cta::before {
        content: '';
        display: block;
        right: 1em;
        top: 50%;
        transform: translateY(-50%);
        position: absolute; }
      .section__top_designers .top-designers__cta-wrapper .top-designers__cta::before {
        background-image: url("../images/sprite.svg");
        background-repeat: no-repeat;
        background-position: 93.19371727748691% 90%;
        width: 17px;
        height: 22px;
        content: '';
        display: block; }

/*********************************/
/* _l-section-section-whys.scss */
/*******************************/
.section_whys {
  padding: 2.5em 0 1em; }
  @media (min-width: 769px) {
    .section_whys {
      padding: 4em 0 1em; } }
  .section_whys .whys-top {
    padding: .5em; }
    .section_whys .whys-top .whys-top__title {
      font-size: 1rem;
      text-align: center; }
      @media (min-width: 461px) {
        .section_whys .whys-top .whys-top__title {
          font-size: 1.25rem; } }
      @media (min-width: 769px) {
        .section_whys .whys-top .whys-top__title {
          font-size: 1.75rem; } }
    .section_whys .whys-top .whys-top__wrapper {
      margin-top: 2em; }
      @media (min-width: 769px) {
        .section_whys .whys-top .whys-top__wrapper {
          display: flex;
          justify-content: space-between;
          margin-top: 3em; } }
      .section_whys .whys-top .whys-top__wrapper .whys-top__item {
        margin-bottom: .75em;
        will-change: transform; }
        @media (min-width: 769px) {
          .section_whys .whys-top .whys-top__wrapper .whys-top__item {
            margin-bottom: 0;
            margin-left: .75em;
            margin-right: .75em;
            text-align: center;
            width: calc(33% - 1.5em); } }
        @media (min-width: 1025px) {
          .section_whys .whys-top .whys-top__wrapper .whys-top__item {
            margin-left: 2em;
            margin-right: 2em; } }
        .section_whys .whys-top .whys-top__wrapper .whys-top__item .whys-top__item-title {
          color: #FFC244;
          font-size: 1.75rem;
          font-weight: 700; }
          @media (min-width: 461px) {
            .section_whys .whys-top .whys-top__wrapper .whys-top__item .whys-top__item-title {
              font-size: 2rem; } }
          @media (min-width: 769px) {
            .section_whys .whys-top .whys-top__wrapper .whys-top__item .whys-top__item-title {
              font-size: 2.375rem; } }
        .section_whys .whys-top .whys-top__wrapper .whys-top__item .whys-top__item-description {
          font-size: 0.875rem; }
          @media (min-width: 461px) {
            .section_whys .whys-top .whys-top__wrapper .whys-top__item .whys-top__item-description {
              font-size: 1rem; } }
          @media (min-width: 769px) {
            .section_whys .whys-top .whys-top__wrapper .whys-top__item .whys-top__item-description {
              font-size: 1.25rem; } }
  .section_whys .whys-bottom {
    background-color: #F3F3F3;
    margin-top: 4em;
    padding: 2em 1em; }
    @media (min-width: 641px) {
      .section_whys .whys-bottom .whys-bottom__wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap; } }
    .section_whys .whys-bottom .whys-bottom__wrapper .whys-bottom__item {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 1em; }
      @media (min-width: 641px) {
        .section_whys .whys-bottom .whys-bottom__wrapper .whys-bottom__item {
          display: block;
          margin-bottom: .25em;
          margin-left: .5em;
          margin-right: .5em;
          max-width: 13.75rem;
          width: calc(20% - 1em); } }
      .section_whys .whys-bottom .whys-bottom__wrapper .whys-bottom__item .whys-bottom__item-image {
        margin-right: 1em;
        max-width: 5em; }
        @media (min-width: 641px) {
          .section_whys .whys-bottom .whys-bottom__wrapper .whys-bottom__item .whys-bottom__item-image {
            margin: 0 auto;
            max-width: 6em; } }
        @media (min-width: 769px) {
          .section_whys .whys-bottom .whys-bottom__wrapper .whys-bottom__item .whys-bottom__item-image {
            max-width: 100%; } }
      .section_whys .whys-bottom .whys-bottom__wrapper .whys-bottom__item .whys-bottom__item-description {
        font-weight: 700;
        margin-top: .75em; }
        @media (min-width: 641px) {
          .section_whys .whys-bottom .whys-bottom__wrapper .whys-bottom__item .whys-bottom__item-description {
            text-align: center; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }
