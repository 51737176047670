/*****************/
/* _l-main.scss */
/***************/

body {
  .main-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    .main {
      flex-grow: 1;
    }
  }
}

.main-wrapper:not(.front-page) {
  padding-top: $header-height;
}