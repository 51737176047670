/*************************/
/* _c-outlet-modal.scss */
/***********************/

.outlet-modal {
  .outlet-modal__body {
    padding: 1.25em;
    .outlet-modal__title {
      color: $color-turquoise;
      font-weight: 700;
      padding-left: .5em;
    }
    .outlet-modal__form-wrapper {
      margin-top: 1.25em;
      .outlet-modal__form {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
  .outlet-modal__actions {
    text-align: center;
    .outlet-modal__cta {
      @include button-full-small;
    }
  }
  .outlet-modal__footer {
    display: flex;
    margin-top: 3.25em;
    padding: 1.25em;
    .outlet-modal__footer-info {
      font-size: $font-small;
      &:not(:last-of-type) {
        margin-right: 1.5em;
      }
    }
  }
}