/************************/
/* _c-card-outlet.scss */
/**********************/

.outlet-card {
  background-color: rgba($color-white, .5);
  border: 1px solid $color-turquoise;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  margin-left: .5em;
  margin-right: .5em;
  overflow: hidden;
  width: 24em;
  &.fluid {
    min-width: 17.5em;
    width: calc(33% - 1em);
    @include breakpoint($xsmall + 1) {
      min-width: 19em;
    }
    @include breakpoint($medium + 1) {
      min-width: 24em;
    }
  }

  .outlet-card__header {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 9em;
    @include breakpoint($small) {
      height: 12em;
    }
    @include breakpoint($medium) {
      height: 15em;
    }
  }
  .outlet-card__body {
    background-color: $color-gray-lightest;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .outlet-card__shop {
      background-color: $color-turquoise;
      color: $color-white;
      font-size: $font-small;
      font-weight: 700;
      padding: .275em .65em;
    }
    .outlet-card__top {
      flex-grow: 1;
      padding: .35em .75em;
      .outlet-card__title {
        color: $color-turquoise;
        font-size: $font-medium;
        font-weight: 700;
        line-height: 1;
        @include breakpoint($small) {
          font-size: $font-discrete;
        }
        @include breakpoint($medium) {
          font-size: $font-intermediate;
        }
      }
      .outlet-card__description {
        font-size: $font-small;
        @include breakpoint($medium) {
          font-size: $font-normal;
        }
      }
      .outlet-card__specs {
        font-size: $font-xsmall;
        font-weight: 700;
        margin-top: .5em;
      }
    }
    .outlet-card__bottom {
      align-items: flex-end;
      background-color: $color-white;
      border-top: 1px solid rgba($color-gray-light, .35);
      display: flex;
      justify-content: space-between;
      padding: .65em;
      .outlet-card__bottom-left {
        .outlet-card__price {
          font-weight: 700;
          text-decoration: line-through;
          font-size: $font-small;
          @include breakpoint($small) {
            font-size: $font-normal;
          }
        }
        .outlet-card__price-discounted {
          color: $color-turquoise;
          font-size: $font-intermediate;
          font-weight: 700;
          line-height: 1;
          @include breakpoint($xsmall) {
            font-size: $font-large;
          }
          @include breakpoint($small) {
            font-size: $font-xlarge;
          }
          @include breakpoint($medium) {
            font-size: $font-xxlarge;
          }
        }
      }
      .outlet-card__bottom-right {
        .outlet-card__cta {
          @include button-empty(true);
          padding: .4em 1em;
          text-transform: none;
          @include breakpoint($small + 1) {
            padding: .45em 1.5em;
            &::before {
              @include sprite--icon-chevron-right-gray;
              content: '';
              display: block;
              opacity: .75;
              right: .35em;
            }
          }
        }
      }
    }
  }
}