/*****************************/
/* _l-section-projects.scss */
/***************************/

.section__projects {
  .projects__title {
    @include title-small;
  }
  .projects__gallery-content-for {
    margin-bottom: .5em;
    .slick-list {
      background-color: $color-gray-lightest;
      .slick-track {
        align-items: center;
        display: flex;
        height: 100%;
        .slick-slide {
          height: 100%;
          div {
            height: 100%;
            text-align: center;
            .detail-hero__gallery-item {
              // height: auto;
              max-height: 100% !important;
              width: auto !important;
            }
          }
        }
      }
    }
    .slick-dots {
      align-items: center;
      display: flex;
      justify-content: center;
      list-style: none;
      margin-top: .5em;
      padding-left: 0;
      li {
        margin-left: .2em;
        margin-right: .2em;
        button {
          border: 1px solid $color-gray-lighter;
          border-radius: 50%;
          height: .5em;
          text-indent: -9999px;
          transition: all $transition-default;
          width: .5em;
        }
        &.slick-active {
          button {
            background-color: $color-gray-lighter;
          }
        }
      }
    }
  }
  .projects__gallery-content-nav {
    display: none;
    @include breakpoint($small) {
      display: block;
    }
    .slick-list {
      .slick-track {
        .slick-slide {

          &.slick-current div {
            background-color: $color-turquoise;
          }
          div {
            background-color: $color-white;
            transition: background-color $transition-default;
            height: 5em !important;
            padding: .25em;
            text-align: center;
            width: auto !important;
            .detail-hero__gallery-item {
              height: 100% !important;
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}