/*********************************/
/* _l-section-landing-left.scss */
/*******************************/

.section-landing-left {
  display: flex;
  flex-direction: column;
  .main__logo {
    flex-shrink: 0;
  }
  .landing__info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    margin: 0 auto;
    max-width: 38em;
    .landing__title {
      color: $color-white;
      font-size: $font-intermediate;
      font-weight: 700;
      margin-bottom: 0em;
      margin-top: 2em;
      @include breakpoint($small) {
        font-size: $font-xlarge;
        margin-bottom: 1em;
      }
      @include breakpoint($intermediate) {
        font-size: $font-xxlarge;
        margin-bottom: 2em;
        margin-top: 0;
      }
    }
  }
}