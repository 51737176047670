.wpcc-container {
  border: 2px solid $color-turquoise;
  font-family: $font-primary;
  font-size: $font-xsmall;
  padding: 1em;
  &.wpcc-bottom {
    bottom: 2em !important;
  }
  &.wpcc-right {
    right: 2em !important;
    @media (max-width: 414px) {
      left: 2em !important;
    }
  }
  .wpcc-message {
    .wpcc-privacy {

    }
  }
  .wpcc-compliance {
    text-align: center;
    .wpcc-btn {
      @include button-full-small;
      font-size: $font-xsmall !important;
      text-transform: none;
    }
  }
}