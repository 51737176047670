/************************************/
/* _l-section-outlet-results.scss */
/**********************************/

.section__outlet-results {
  .outlet-results__summary {
    margin-left: 1em;
    margin-top: 1.25em;
    .outlet-results__title {
      font-weight: 700;
    }
    .outlet-results__nresults {
      color: $color-turquoise;
      margin-top: .5em;
    }
    .outlet-results__breadcrumb {
      font-size: $font-xsmall;
    }
  }
  .outlet-results__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2em;
    @include breakpoint($large) {
      justify-content: flex-start;
    }
  }
  .outlet-results__paginator {
    margin: 1.5em auto 3.5em;
  }
}