/***********************************/
/* _l-page-arredatore-detail.scss */
/*********************************/

.arredatori-dettaglio-page {
  .arredatori-dettaglio__wrapper {
    display: flex;
    .arredatori-dettaglio__wrapper-left {
      width: 100%;
      @include breakpoint($large) {
        padding-right: 1em;
        width: 70%;
      }
    }
    .arredatori-dettaglio__wrapper-right {
      width: 0;
      @include breakpoint($large) {
        padding-left: .5em;
        width: 30%;
      }
    }
  }
}