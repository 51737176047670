/**************************/
/* _l-section-hours.scss */
/************************/

.section__hours {
  background-color: $color-white;
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.17);
  border-radius: 8px;
  margin-top: -10em;
  padding: 1.5em;
  .hours-box__logo-wrapper {
    .hours-box__logo {
      margin: 0 auto 1em;
    }
  }
  .hours-box__body {
    .hours-box__title {
      font-weight: 700;
    }
    .hours-box__info {
      margin-top: .2em;
      .hours-box__info-row {
        display: flex;
        font-size: $font-small;
        justify-content: space-between;
        padding: .12em;
        &.current {
          background-color: rgba($color-turquoise, .15);
          font-weight: 700;
        }
      }
    }
  }
  .hours-box__footer {
    border-top: 1px solid rgba($color-gray-lighter, .5);
    margin-top: 1.5em;
    padding-top: 1em;
    .hours-box__website {
      align-items: center;
      color: $color-gray;
      display: flex;
      font-weight: 700;
      text-decoration: none;
      &::before {
        @include sprite--icon-web;
        content: '';
        display: block;
        margin-right: .15em;
        margin-top: .1em;
      }
    }
    .hours-box__phone-cta {
      font-weight: 700;
      text-decoration: none;
    }
  }
  &.pinnable {
    display: none;
    @include breakpoint($large) {
      display: block;
    }
  }
  &.responsive {
    display: block;
    margin-top: 2em;
    @include breakpoint($large) {
      display: none;
    }
  }
}