/******************/
/* _c-steps.scss */
/****************/

.steps {
  .step {
    align-items: center;
    display: flex;
    margin-bottom: 2em;
    margin-top: 2em;
    .step__image {
      align-items: center;
      background-color: $color-white;
      border-radius: 50%;
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      margin-right: 1em;
      height: 3em;
      width: 3em;
      @include breakpoint($medium) {
        height: 4em;
        width: 4em;
      }
      img {
        max-height: 2em;
        max-width: 2em;
        @include breakpoint($medium) {
          max-height: 2.75em;
          max-width: 2.75em;
        }
      }
    }
    .step__description {
      color: $color-white;
      font-size: $font-discrete;
      line-height: 1.15;
      @include breakpoint($medium) {
        font-size: $font-large;
      }
    }
  }
}