/************************************/
/* _l-section-outlet-designer.scss */
/**********************************/

.section__outlet-designer {
  margin-bottom: 3em;
  .outlet-designer__title {
    @include title-small;
  }
  .outlet-designer__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .outlet-designer__bottom {
    margin-top: 1.5em;
    text-align: center;
    .outlet-designer__cta {
      @include button-empty(true);
      &::before {
        @include sprite--icon-chevron-right-gray;
        content: '';
        display: block;
      }
    }
  }
}