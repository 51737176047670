/*****************/
/* _c-logo.scss */
/***************/

#main-logo {
  // height: 2.75rem;
  transition: all $transition-default;
  width: 9em;
  @include breakpoint($normal) {
    // height: 3.9rem;
    width: auto;
  }
  .logo-negative {
    transition: opacity $transition-default;
    opacity: 0;
    @include breakpoint($normal) {
      opacity: 1;
    }
  }
}

.sticky #main-logo {
  height: 2.75rem;
  .logo-negative {
    opacity: 0;
  }
}
