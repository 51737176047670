/*********************************/
/* _l-section-detailheader.scss */
/*******************************/

.detail-header {
  padding-bottom: .5em;
  @include breakpoint($small) {
    padding-bottom: 2em;
  }
  .detail-header__top {
    @include maxWidth;
    .detail-header__top-wrapper {
      padding-bottom: 1.25em;
      padding-top: 1.25em;
      @include breakpoint($small) {
        display: flex;
        justify-content: space-between;
      }
      @include breakpoint($large) {
        max-width: 65%;
      }
      .detail-header__top-left {
        .deatil-header__title {
          color: $color-turquoise;
          font-size: $font-intermediate;
          line-height: 1;
          @include breakpoint($xsmall) {
            font-size: $font-large;
          }
          @include breakpoint($small) {
            font-size: $font-xlarge;
          }
          @include breakpoint($medium) {
            font-size: $font-xxlarge;
          }
        }
        .deatil-header__address {
          font-size: $font-small;
          font-weight: 500;
          line-height: 1.1;
          @include breakpoint($xsmall) {
            font-size: $font-normal;
          }
          @include breakpoint($small) {
            font-size: $font-medium;
          }
          @include breakpoint($medium) {
            font-size: $font-discrete;
          }
        }
      }
      .detail-header__top-right {
        .detail-header__stars {
          justify-content: flex-start;
          @include breakpoint($small) {
            justify-content: flex-end;
          }
        }
      }
    }
  }
  .detail-header__menu {
    display: none;
    @include breakpoint($small) {
      border-bottom: 1px solid rgba($color-gray-light, .125);
      border-top: 1px solid rgba($color-gray-light, .125);
      display: block;
    }
    .detail-menu__wrapper {
      @include maxWidth;
      padding-bottom: .625em;
      padding-top: .625em;
      .detail-menu__list {
        display: flex;
        list-style: none;
        padding-left: 0;
        @include breakpoint($large) {
          max-width: 70%;
        }
        .detail-menu__item {
          margin-left: .45em;
          margin-right: .45em;
          @include breakpoint($normal) {
            margin-left: .75em;
            margin-right: .75em;
          }
          &:first-of-type {
            margin-left: 0;
          }
          .detail-menu__link {
            color: $color-gray-light;
            font-size: $font-xsmall;
            font-weight: 700;
            text-decoration: none;
            text-transform: uppercase;
            transition: color $transition-default;
            @include breakpoint($medium) {
              font-size: $font-small;
            }
            &:hover {
              color: $color-gray;
            }
          }
        }
      }
    }
  }
}