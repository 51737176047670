/*******************/
/* _l-header.scss */
/*****************/

.header {
  background-color: rgba($color-white, .95);
  border-bottom: 1px solid $color-gray-lighter;
  height: $header-height;
  left: 0;
  padding: 1em;
  position: fixed;
  right: 0;
  top: 0;
  transition: all $transition-default;
  width: 100%;
  z-index: 2;
  @include breakpoint($normal) {
    background-color: rgba($color-white, 0.01);
    border-bottom: none;
    height: auto;
  }
  .header__wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    .header__menu {
      align-items: center;
      background-color: $color-white;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      left: 0;
      position: fixed;
      right: 0;
      top: $header-height;
      transform: translateX(120%);
      transition: transform $transition-slowmo;
      z-index: -1;
      @include breakpoint($normal) {
        background-color: rgba($color-white, 0);
        flex-direction: row;
        position: static;
        transform: translateX(0%);
        z-index: 0;
      }
      &.open-menu {
        transform: translateX(0%);
      }
    }
    .header__menu-icon {
      background-image: url('../images/icons/icon-menu-burger.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      cursor: pointer;
      display: block;
      height: 2em;
      transition: background-image $transition-default;
      width: 2em;
      @include breakpoint($normal) {
        display: none;
      }
      &.close-icon {
        background-image: url('../images/icons/icon-close.svg');
      }
    }
  }
  &.sticky {
    background-color: rgba($color-white, .95);
    border-bottom: 1px solid $color-gray-lighter;
    height: $header-height;
  }
}
