/******************/
/* _c-slick.scss */
/****************/

.slick {
  opacity: 0;
  transition: opacity $transition-slowmo;
  &.slick-initialized {
    opacity: 1;
  }
}