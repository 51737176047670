/******************/
/* _c-modal.scss */
/****************/

.modal {
  background-color: rgba($color-turquoise, .85);
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: all $transition-default;
  z-index: -1;
  &.show {
    opacity: 1;
    pointer-events: all;
    z-index: 2;
  }
  .modal__wrapper {
    background-color: $color-white;
    border-radius: 8px;
    bottom: 0;
    box-shadow: 0 0 8px 0 rgba($color-black,.2);
    left: 0;
    margin: auto;
    max-height: 32em;
    max-width: 45em;
    position: absolute;
    right: 0;
    top: 0;
    width: 95%;
    .modal_header {
      align-items: center;
      border-bottom: 1px solid rgba($color-gray-lighter, .5);
      display: flex;
      justify-content: space-between;
      padding: .75em 1.25em;
      .modal__title {
        font-size: $font-large;
      }
      .modal__close {
        @include sprite--icon-close;
        cursor: pointer;
        margin-right: -.5em;
      }
    }
    .modal__body {

    }
    .modal__actions {

    }
    .modal__footer {

    }
  }
}