/***********************/
/* _c-breadcrumb.scss */
/*********************/

.breadcrumb {
  &.detail-header__breadcrumb  {
    .breadcrumb__wrapper {
      @include maxWidth;
      padding-bottom: .35em;
      padding-top: .35em;
      .breadcrumb__list {
        align-items: center;
        display: flex;
        list-style: none;
        padding-left: 0;
        .breadcrumb__item {
          align-items: center;
          display: flex;
          &:not(:last-of-type) {
            &::after {
              content: '/';
              display: block;
              font-size: $font-xsmall;
              margin-left: .35em;
              margin-right: .35em;
              @include breakpoint($small) {
                margin-left: .65em;
                margin-right: .65em;
              }
            }
          }
          .breadcrumb__link {
            color: $color-gray;
            font-size: $font-xsmall;
            font-weight: 700;
            text-decoration: none;
            transition: color $transition-default;
            &:hover {
              color: $color-gray-light;
            }
            &.current {
              color: $color-gray-light;
            }
          }
        }
      }
    }
  }
}